import { configureStore } from "@reduxjs/toolkit";
import itemsReducer from "./itemsSlice";
import selectedItemReducer from "./selectedItemSlice";
import itemsStatusReducer from "./itemsStatusSlice";
import projectFileReducer from "./projectFilesSlice";
import opportunitiesReducer from "./opportunitiesSlice";

export const store = configureStore({
  reducer: {
    items: itemsReducer,
    selectedItem: selectedItemReducer,
    itemsStatus: itemsStatusReducer,
    projectFiles: projectFileReducer,
    opportunities: opportunitiesReducer,
  },
});
