import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Switch,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";

const FilenameTextField = ({ initialFilename, onChange }) => {
  const [baseName, setBaseName] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    // Extract the base name from the initial filename
    const baseNameMatch = initialFilename.match(/^(.+)\.zip$/);
    if (baseNameMatch) {
      setBaseName(baseNameMatch[1]);
    } else {
      setBaseName(initialFilename);
    }
  }, [initialFilename]);

  useEffect(() => {
    // Select the text in the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.select();
    }
  }, []);

  const handleChange = (e) => {
    const newBaseName = e.target.value;
    setBaseName(newBaseName);
    onChange(newBaseName);
  };

  return (
    <TextField
      fullWidth
      label="File Name"
      value={baseName}
      onChange={handleChange}
      inputRef={inputRef}
      InputProps={{
        endAdornment: <InputAdornment position="end">.zip</InputAdornment>,
      }}
      onFocus={(e) => e.target.select()} // Select all text when focused
    />
  );
};

// Styled components
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-primary": {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5),
  },
  "& .MuiListItemText-secondary": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const StyledInfoIcon = styled(InfoIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledListItemIcon = styled(ListItemIcon)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '1rem',
});

const ExportModal = ({ open, onClose, onExport, projectName }) => {
  const [filename, setFilename] = useState(`${projectName}.zip`);
  const [includeTodos, setIncludeTodos] = useState(false);

  useEffect(() => {
    if (open) {
      setFilename(`${projectName}.zip`);
      setIncludeTodos(false);
    }
  }, [open, projectName]);

  const handleExport = () => {
    onExport(filename, includeTodos);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="export-modal-title"
      aria-describedby="export-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "80%",
            sm: "80%",
            md: "70%",
            lg: "60%",
            xl: "50%",
          },
          maxWidth: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: { xs: 2, sm: 3, md: 4 },
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="sectionHeading"
          component="h2"
          gutterBottom
          sx={{ color: "black", mb: 2 }}
        >
          Export Document
        </Typography>
        <Typography variant="primaryText" sx={{ mt: 2, mb: 2 }}>
          Please review the following information before exporting:
        </Typography>
        <List>
          <ListItem>
            <StyledListItemIcon>
              <StyledInfoIcon />
            </StyledListItemIcon>
            <StyledListItemText
              primary="Citations"
              secondary="In text citations will be removed during export. Make sure to review the content accordingly."
            />
          </ListItem>
          <ListItem>
            <StyledListItemIcon>
              <StyledInfoIcon />
            </StyledListItemIcon>
            <StyledListItemText
              primary="Versions"
              secondary="Only the latest version of each section will be exported. If needed, edit the latest version to include content from previous versions before exporting."
            />
          </ListItem>
          <ListItem>
            <StyledListItemIcon>
              <StyledInfoIcon />
            </StyledListItemIcon>
            <StyledListItemText
              primary="Form Files"
              secondary="All form files will be added as separate files in the zip folder."
            />
          </ListItem>
          <ListItem>
            <StyledListItemIcon>
              <Switch
                checked={includeTodos}
                onChange={(e) => setIncludeTodos(e.target.checked)}
                color="primary"
              />
            </StyledListItemIcon>
            <StyledListItemText
              primary="TODOs"
              secondary={
                includeTodos
                  ? "Choose whether to include or exclude TODOs in the exported document. TODOs will now be: Included"
                  : "Choose whether to include or exclude TODOs in the exported document. TODOs will now be: Excluded"
              }
            />
          </ListItem>
        </List>
        <FilenameTextField initialFilename={filename} onChange={setFilename} />
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleExport} variant="contained">
            Export
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ExportModal;