import React, { useRef, useImperativeHandle, forwardRef, useEffect } from "react";
import { Worker, Viewer, ScrollMode } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { createStore } from "@react-pdf-viewer/core";
import { Typography, Box, IconButton } from "@mui/material";
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from '@mui/material/Tooltip';
import LinkOffRoundedIcon from '@mui/icons-material/LinkOffRounded';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';

const customNavigationPlugin = () => {
  const store = React.useMemo(() => createStore({}), []);

  return {
    install: (pluginFunctions) => {
      store.update("jumpToPage", pluginFunctions.jumpToPage);
    },
    jumpToPage: (pageIndex) => {
      const jump = store.get("jumpToPage");
      if (jump) {
        jump(pageIndex);
      }
    },
  };
};



const PDFViewer = forwardRef(
  ({ fileUrl, initialPage, showToolbar = true, defaultScale = 1.1, fileName, onDownload, onDelete, refreshUrl }, ref) => {
    const viewerRef = useRef(null);
    const currentPageRef = useRef(initialPage);

    const customNavigationPluginInstance = customNavigationPlugin();
    const { jumpToPage } = customNavigationPluginInstance;

    useImperativeHandle(ref, () => ({
      getCurrentPage: () => currentPageRef.current,
      jumpPage: (pageIndex) => {
        jumpToPage(pageIndex);
      },
    }));

    const handlePageChange = (e) => {
      currentPageRef.current = e.currentPage;
    };

    const pageLayout = {
      transformSize: ({ size }) => ({
        height: size.height + 10,
        width: size.width + 10,
      }),
    };

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
      ...slot,
      Download: () => <></>,
      Open: () => () => <></>,
      SwitchTheme: () => <></>,
      SwitchScrollModeMenuItem: (props) => {
        if (props.mode === ScrollMode.Horizontal) {
          return <></>;
        }
        if (props.mode === ScrollMode.Wrapped) {
          return <></>;
        }
        return <slot.SwitchScrollModeMenuItem {...props} />;
      },
    });

    useEffect(() => {
      const validateUrl = async () => {
        try {
          const response = await fetch(fileUrl);
          if (response.status === 400) {
            await refreshUrl();
          }
        } catch (error) {
          console.error("Error validating URL:", error);

        }
      };

      validateUrl();
    }, [fileUrl]);


    return (
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "var(--border-radius)",
          margin: "0rem 0.3rem 0.3rem 0.3rem",
          height: "calc(100vh - 4rem)",
        }}
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <div style={{ height: "100%" }}>
            {fileName &&
              <Box sx={{ bgcolor: "background.paper", display: "flex", justifyContent: "space-between", px: 1, py: 1, borderBottom: "1px solid #ddd" }}>
                <Box sx={{ width: "60%", display: "flex", alignItems: "center", cursor: "default" }} title={fileName}>
                  <Typography variant="primaryText" noWrap sx={{ textOverflow: "ellipsis" }}>
                    {fileName}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "start" }}>
                  <Tooltip title="Download" placement="top" arrow >
                    <IconButton onClick={onDownload}>
                      <DownloadRoundedIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Detach File" placement="top" arrow >
                    <IconButton onClick={onDelete}>
                      <DoDisturbAltRoundedIcon sx={{ fontSize: "1rem" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            }

            {showToolbar && (
              <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            )}
            <div
              style={{
                height: showToolbar || fileName ? "calc(100% - 80px)" : "calc(100% - 40px)",
                overflow: "auto",
                borderRadius: "var(--border-radius)",
              }}
            >
              <Viewer
                ref={viewerRef}
                plugins={[
                  toolbarPluginInstance,
                  customNavigationPluginInstance,
                ]}
                fileUrl={fileUrl}
                defaultScale={defaultScale}
                pageLayout={pageLayout}
                onPageChange={handlePageChange}
                initialPage={initialPage}
              />
            </div>
          </div>
        </Worker>
      </div>
    );
  }
);

export default PDFViewer;