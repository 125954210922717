import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  RichTextEditorProvider,
  HeadingWithAnchor,
  ResizableImage,
  TableImproved,
} from "mui-tiptap";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ListItem } from "@tiptap/extension-list-item";
import { Link } from "@tiptap/extension-link";
import { TextAlign } from "@tiptap/extension-text-align";
import { Underline } from "@tiptap/extension-underline";
import { TableRow } from "@tiptap/extension-table-row";
import { TableHeader } from "@tiptap/extension-table-header";
import { TableCell } from "@tiptap/extension-table-cell";
import { OrderedList } from "@tiptap/extension-ordered-list";
import { BulletList } from "@tiptap/extension-bullet-list";
import { Paragraph } from "@tiptap/extension-paragraph";

const StyledEditorContent = styled(EditorContent)(({ theme }) => ({
  "& .ProseMirror": {
    "& p": {
      fontSize: "0.875rem",
      lineHeight: 1.5,
      color: "#757575",
    },
    "& h1": { fontSize: "1rem" },
    "& h2": { fontSize: "0.95rem" },
    "& h3": { fontSize: "0.9rem" },
    "& h4": { fontSize: "0.89rem" },
    "& h5": { fontSize: "0.83rem" },
    "& h6": { fontSize: "0.67rem" },
  }
}));

const CustomLink = Link.extend({
  inclusive: false,
});

const CustomListItem = ListItem.extend({
  renderHTML({ HTMLAttributes }) {
    return ["li", { ...HTMLAttributes, style: "margin-left: 1em;" }, 0];
  },
});

const ReadOnlyTipTapEditor = ({ content }) => {
  const extensions = useMemo(
    () => [
      StarterKit.configure({
        heading: false,
      }),
      TableImproved.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      CustomListItem,
      BulletList,
      OrderedList,
      CustomLink.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph", "image"],
      }),
      Paragraph,
      HeadingWithAnchor,
      Underline,
      ResizableImage,
    ],
    []
  );

  const editor = useEditor({
    extensions,
    content,
    editable: false,
  });

  if (!editor) {
    return null;
  }

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column", mr: 1.5 }}>
      <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
        <RichTextEditorProvider editor={editor}>
          <StyledEditorContent editor={editor} />
        </RichTextEditorProvider>
      </Box>
    </Box>
  );
};

export default ReadOnlyTipTapEditor;