import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../store";

// Async thunk for fetching opportunities
export const fetchOpportunities = createAsyncThunk(
  'opportunities/fetchOpportunities',
  async () => {
    const response = await api.get("/capture/getOpportunities");
    const filteredOpportunities = response.data.opportunities
      .filter(opp => opp.scoring.score >= 1)
      .filter(opp => new Date(opp.deadline).toLocaleDateString() > new Date().toLocaleString());
    return filteredOpportunities;
  }
);

// Async thunk for updating an opportunity
export const updateOpportunity = createAsyncThunk(
  'opportunities/updateOpportunity',
  async ({ rfx_id, updates }) => {
    const response = await api.put(`/capture/opportunities/${rfx_id}`, updates);
    return { rfx_id, updates };
  }
);

const opportunitiesSlice = createSlice({
  name: "opportunities",
  initialState: {
    items: [],
    loading: false,
    error: null
  },
  reducers: {
    setOpportunities: (state, action) => {
      state.items = action.payload;
    },
    updateOpportunityLocally: (state, action) => {
      const { rfx_id, changes } = action.payload;
      const opportunity = state.items.find(opp => opp.rfx_id === rfx_id);
      if (opportunity) {
        Object.assign(opportunity, changes);
      }
    }
  },
  extraReducers: (builder) => {
    builder
      // Fetch opportunities cases
      .addCase(fetchOpportunities.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOpportunities.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchOpportunities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Update opportunity cases
      .addCase(updateOpportunity.fulfilled, (state, action) => {
        const { rfx_id, changes } = action.payload;
        const opportunity = state.items.find(opp => opp.rfx_id === rfx_id);
        if (opportunity) {
          Object.assign(opportunity, changes);
        }
      })
      .addCase(updateOpportunity.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// Export actions
export const { setOpportunities, updateOpportunityLocally } = opportunitiesSlice.actions;

export const selectViewedStatus = (state, opportunityId, userId) => {
  const opportunity = state.opportunities.items.find(opp => opp.rfx_id === opportunityId);
  return opportunity?.viewedBy?.some(view => view.userId === userId) || false;
};

export const selectFavoriteStatus = (state, opportunityId, userId) => {
  const opportunity = state.opportunities.items.find(opp => opp.rfx_id === opportunityId);
  return opportunity?.favoritedBy?.some(fav => fav.userId === userId) || false;
};

// Export selectors
export const selectOpportunities = (state) => state.opportunities.items;
export const selectOpportunityById = (state, rfx_id) =>
  state.opportunities.items.find(opp => opp.rfx_id === rfx_id);
export const selectOpportunitiesLoading = (state) => state.opportunities.loading;
export const selectOpportunitiesError = (state) => state.opportunities.error;

// Export reducer
export default opportunitiesSlice.reducer;
