import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Grid,
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { api, getProfile } from "../store";
import { useSelector, useDispatch } from "react-redux";
import { selectOpportunities, updateOpportunityLocally, fetchOpportunities, selectOpportunitiesLoading, selectOpportunitiesError } from "../reduxstore/opportunitiesSlice";
import CustomTableToolbar from "../Components/rfp/CustomTableToolbar";
import StatusCell from "../Components/rfp/StatusCell";

const ConfidenceScore = ({ score }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginTop: "15%" }}>
      {[1, 2, 3, 4, 5].map((index) => (
        <Box
          key={index}
          sx={{
            width: 13,
            height: 10,
            backgroundColor: index <= score ? 'success.main' : 'grey.200',
            border: 1,
            borderColor: 'grey.300'
          }}
        />
      ))}
    </Box>
  );
};

const LoadingSkeleton = () => (
  <Box
    sx={{
      height: 'calc(100vh - 200px)',
      width: '100%',
      backgroundColor: 'background.paper',
      borderRadius: 2,
      boxShadow: 1,
      p: 2
    }}
  >
    {[...Array(5)].map((_, index) => (
      <Box key={index} sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={300} height={40} />
        <Skeleton variant="rectangular" width={200} height={40} />
        <Skeleton variant="rectangular" width={100} height={40} />
        <Skeleton variant="rectangular" width={150} height={40} />
        <Skeleton variant="rectangular" width={50} height={40} />
      </Box>
    ))}
  </Box>
);

const Opportunities = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const opportunities = useSelector(selectOpportunities);
  const loading = useSelector(selectOpportunitiesLoading);
  const error = useSelector(selectOpportunitiesError);
  const dispatch = useDispatch();
  const user = getProfile();
  const [paginationModel, setPaginationModel] = useState({
    pageSize: Number(searchParams.get('pageSize')) || 10,
    page: Number(searchParams.get('page')) || 0,
  });
  const location = useLocation();
  const [lastViewedId, setLastViewedId] = useState(location.state?.lastViewedId || null);
  const [filterModel, setFilterModel] = useState({
    quickFilterValues: [],
    items: [],
  });
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [matchRelevanceScore, setMatchRelevanceScore] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState('all');

  useEffect(() => {
    if (location.state?.lastViewedId) {
      setLastViewedId(location.state.lastViewedId);
    }
  }, [location.state]);

  useEffect(() => {
    setSearchParams({
      page: paginationModel.page.toString(),
      pageSize: paginationModel.pageSize.toString()
    });
  }, [paginationModel, setSearchParams]);

  const getGridHeight = () => {
    // Each row is approximately 52px high (default MUI DataGrid row height)
    // Header is approximately 56px
    // Pagination controls are approximately 52px
    const rowHeight = 52;
    const headerHeight = 56;
    const paginationHeight = 52;

    if (paginationModel.pageSize <= 5) {
      // For 5 or fewer rows, calculate exact height needed
      return `${(rowHeight * paginationModel.pageSize) + headerHeight + paginationHeight}px`;
    } else {
      // For more rows, set a fixed height with scroll
      return 'calc(100vh - 150px)';
    }
  };

  useEffect(() => {
    const baseData = opportunities
      .filter(opp => opp.scoring.score >= matchRelevanceScore)
      .filter(opp =>
        selectedStatus === 'all' ||
        (opp.status !== undefined ? opp.status.some(s => s.userId === user._id && s.status === selectedStatus) : false) ||
        opp.favoritedBy?.some(fav => fav.userId === user._id && selectedStatus === 'favorite' && (opp.status === undefined || opp.status.some(s => s.userId != user._id)))
      )
      .filter(opp =>
        selectedCategories.length === 0 || selectedCategories.includes(opp.category)
      );

    const processedData = baseData.map(opp => ({
      ...opp,
      matchScore: opp.scoring.score,
      location: `${opp.state}, ${opp.country}`,
      issuer: opp.overview?.issuer
    }));

    setFilteredRows(processedData);
  }, [opportunities, matchRelevanceScore, selectedCategories, selectedStatus, user._id]);


  useEffect(() => {
    if (opportunities.length === 0) {
      dispatch(fetchOpportunities());
    }
  }, [dispatch]);

  const handleRowClick = async (params) => {
    try {
      const currentStatus = params.row.status || 'new';
      if (currentStatus === 'new') {
        // Update to viewed only if current status is new
        dispatch(updateOpportunityLocally({
          rfx_id: params.row.rfx_id,
          changes: {
            status: 'viewed'
          }
        }));

        api.put(`/capture/opportunities/${params.row.rfx_id}`, {
          status: 'viewed'
        });
      }

      navigate(
        `/opportunities/${params.row.rfx_id}?page=${encodeURIComponent(paginationModel.page)}&pageSize=${encodeURIComponent(paginationModel.pageSize)}`,
        { state: { user } }
      );
    } catch (err) {
      console.error("Error updating viewed status:", err);
    }
  };

  const handleStatusChange = async (rfx_id, newStatus) => {
    try {
      // Optimistically update UI
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: [
            ...(opportunities.find(o => o.rfx_id === rfx_id)?.status || []).filter(s => s.userId !== user._id),
            {
              userId: user._id,
              status: newStatus,
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));

      // Update backend
      await api.put(`/capture/opportunities/${rfx_id}`, {
        status: newStatus
      });
    } catch (err) {
      console.error("Error updating status:", err);
      // Revert on error
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: [
            ...(opportunities.find(o => o.rfx_id === rfx_id)?.status || []).filter(s => s.userId !== user._id),
            {
              userId: user._id,
              status: '',
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));
    }
  };

  const columns = [
    {
      field: 'matchScore',
      headerName: 'Match Relevance',
      width: 150,
      sortable: true,
      renderCell: (params) => {
        return <ConfidenceScore score={params.row.matchScore} />
      },
      sortComparator: (v1, v2) => v1 - v2,
      filterable: true,
      hideable: false,
      disableColumnMenu: true,
      filterOperators: [
        {
          label: 'equals',
          value: 'equals',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          label: 'greater than',
          value: 'greaterThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value > filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[1, 2, 3, 4].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        },
        {
          label: 'less than',
          value: 'lessThan',
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value < filterItem.value;
            };
          },
          InputComponent: ({ item, applyValue }) => (
            <FormControl fullWidth size="small">
              <Select
                value={item.value || ''}
                onChange={(e) => applyValue({ ...item, value: e.target.value })}
              >
                {[2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ),
        }
      ]
    },

    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
      minWidth: 350,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'location',
      headerName: 'Location',
      flex: 1,
      minWidth: 50,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'issuer',
      headerName: 'Issuer',
      flex: 1,
      minWidth: 50,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'publication_date',
      headerName: 'Published Date',
      width: 150,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'deadline',
      headerName: 'Due Date',
      width: 120,
      valueFormatter: (params) => {
        return new Date(params).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });
      },
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      sortComparator: (v1, v2) => new Date(v1) - new Date(v2)
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 120,
      sortable: true,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%', height: '100%', marginLeft: 1 }}>
          <StatusCell
            params={params}
            onStatusChange={handleStatusChange}
            userId={user._id}

          />
        </Box>

      )
    }
  ];

  if (loading) {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "70vh"
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ margin: "1.5rem 2rem" }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const getRowClassName = (params) => {
    const isViewed = params.row.viewedBy?.some(
      view => view.userId === user._id
    );
    const isLastViewed = params.row.rfx_id === lastViewedId;

    return `${isViewed ? 'viewed-row' : ''} ${isLastViewed ? 'last-viewed-row' : ''}`.trim();
  };


  return (
    <Box sx={{ margin: "1.5rem 2rem" }} >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
          mt: 4
        }}
      >
        <Typography variant="pageHeading">Opportunities</Typography>

      </Box>

      {
        error ? (
          <Alert severity="error" > {error}</Alert>
        ) : loading ? (
          <LoadingSkeleton />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Box
                sx={{
                  height: getGridHeight(),
                  width: '100%',
                  '& .MuiDataGrid-root': {
                    border: 'none',
                    backgroundColor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 1
                  },
                  '& .MuiDataGrid-cell': {
                    fontSize: '0.875rem'
                  },
                  '& .MuiDataGrid-columnHeaders': {
                    backgroundColor: 'background.paper',
                    borderBottom: 1,
                    borderColor: 'divider'
                  },
                  '& .viewed-row': {
                    backgroundColor: 'rgba(234, 242, 244, 0.8)',
                    '&:hover': {
                      backgroundColor: 'rgba(234, 242, 244, 0.8)',
                    }
                  },
                  '& .last-viewed-row': {
                    // dont add top border
                    borderTop: '1px solid',
                    borderRight: '1.8px solid',
                    borderLeft: '1.8px solid',
                    borderBottom: '1.5px solid',
                    borderColor: 'primary.main',
                    '& .MuiDataGrid-cell': {
                      borderColor: 'primary.main',
                    }
                  }
                }}
              >
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  getRowId={(row) => row.rfx_id}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  pageSizeOptions={[5, 10, 25, 50]}
                  filterMode="server"
                  onFilterModelChange={(newModel) => setFilterModel(newModel)}
                  filterModel={filterModel}
                  disableRowSelectionOnClick
                  onRowClick={handleRowClick}
                  rowCount={filteredRows.length}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'publication_date', sort: 'desc' }],
                    },
                  }}
                  getRowClassName={getRowClassName}
                  slots={{
                    toolbar: (props) => (
                      <CustomTableToolbar
                        {...props}
                        opportunities={opportunities}
                        selectedCategories={selectedCategories}
                        onCategoryChange={setSelectedCategories}
                        selectedStatus={selectedStatus}
                        onStatusChange={setSelectedStatus}
                        matchRelevanceScore={matchRelevanceScore}
                        onMatchRelevanceChange={(value) => {
                          setMatchRelevanceScore(value)
                        }}

                      />
                    )
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 300 },
                    },
                  }}
                  localeText={{
                    filterOperatorCategoryFilter: 'Select Categories', // Add translation for the operator
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )}
    </Box >
  );
};

export default Opportunities;