import React from 'react';
import { Paper, styled } from '@mui/material';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import PDFViewer from "./PDFViewer";
import { useSelector } from "react-redux";

const ViewerPanel = styled(Paper)(({ theme }) => ({
  width: "calc(80% - 8px)",
  minWidth: "calc(80% - 8px)",
  maxWidth: "calc(80% - 8px)",
  height: "100%",
  border: "1px solid #ddd",
  ...theme.typography.body2,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const DocumentViewerPanel = ({ tab, pdfViewerRef, lastPages, refreshUrl }) => {
  const projectFiles = useSelector((state) => state.projectFiles);

  if (
    projectFiles.currentFile &&
    projectFiles.currentFile.metadata.metadata.contentType === "application/pdf"
  ) {
    return (
      <ViewerPanel>
        {tab === "documents" && (
          <PDFViewer
            ref={pdfViewerRef}
            fileUrl={projectFiles.currentFile.uri}
            initialPage={lastPages[projectFiles.currentFile.uri] || 1}
            refreshUrl={refreshUrl}
          />
        )}
      </ViewerPanel>
    );
  } else {
    return (
      <ViewerPanel>
        <DocViewer
          documents={projectFiles.files}
          activeDocument={projectFiles.currentFile}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          config={{
            header: {
              disableHeader: true,
            },
            pdfVerticalScrollByDefault: true,
            className: "custom-doc-viewer",
          }}
          style={{
            height: "100%",
            width: "100%",
            maxHeight: "100%",
            overflow: "auto",
          }}
        />
      </ViewerPanel>
    );
  }
};

export default DocumentViewerPanel;