import React, { useState, useEffect, useRef } from "react";
import {
  Search,
  Loading,
  Accordion,
  AccordionItem,
  Button,
  Modal,
  TextArea,
  Grid,
  Column,
  OverflowMenu,
  OverflowMenuItem,
} from "@carbon/react";
import { Typography } from "@mui/material";
import {
  Document,
  Launch,
  ThumbsDown,
  Copy,
  ErrorFilled,
  Upload,
  Email,
  LogoSlack,
  ManagedSolutions,
  Time,
} from "@carbon/icons-react";
import CMSTiles from "../Components/archives/CMSTiles";
import { supportedCMS } from "../util";
import { api, api_external } from "../store";
import "./Archives.scss";
import ReactPlayer from "react-player";
import env from "../config";
import Loader from "react-loaders";
import { useNavigate } from "react-router-dom";
import Fuse from "fuse.js";
import SharePointConfig from "../Components/SharepointConfig";

const Archives = (searchError) => {
  const navigate = useNavigate();
  const [rowData, setRows] = useState(["dummy"]);
  const [showSearchDiv, setShowSearchDiv] = useState(true);
  const [showSearchDivContent, setShowSearchDivContent] = useState(false);
  const [passiveModalContent, setPassiveModalContent] = useState("");
  const [userArchiveIDs, setUserArchiveIDs] = useState([]);
  const [isArchiveConfigured, setArchiveConfiguration] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [manualArchiveId, setManualArchiveId] = useState("");
  const [askArchivesError, setAskArchivesError] = useState(false);
  const [image_urls, setImageUrls] = useState([]);
  const [question, setQuestion] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isGenFeedbackModelOpen, setFeedbackModalState] = useState(false);
  const [dislike, setDislike] = useState("");
  const [videoUrl, setVideoUrl] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const playerRef = useRef(null);
  const [user, setUser] = useState({});
  const fuseOptions = {
    keys: ["question"],
    threshold: 0.5,
    includeScore: true,
  };

  const handleDislikeChange = (event) => {
    setDislike(event.target.value);
  };

  const getArchives = async (archiveIds) => {
    const idsString = archiveIds
      .map((item) => `${item.id}:${item.archiveType}`)
      .join(",");
    let response = await api.get(
      "/archives/getUserArchives?userArchivesId=" + idsString
    );
    const archives = response.data;
    if (archives.length > 0) {
      setRows(archives);
    }
  };

  const getUserArchivesId = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setArchiveConfiguration(true);
      setUserArchiveIDs(response.data.archives);
      const manualArchive =
        response.data.archives.find(
          (archive) => archive.archiveType === "uploadFiles"
        ) || null;
      if (manualArchive !== null) {
        setShowUploadButton(true);
        setManualArchiveId(manualArchive.id);
      }
      await getArchives(response.data.archives);
    } else {
      setArchiveConfiguration(false);
      setUserArchiveIDs([]);
    }
  };

  const getUser = async () => {
    let response = await api.get("user/getUserProfile");
    setUser(response.data);
  };

  useEffect(() => {
    //get archive IDs of the user
    getUserArchivesId();
    getUser();
  }, []);

  const ideaResponse = {
    answer: [
      {
        content:
          "1. The Environment Fund: The article could focus on the contributions of various countries since the past few years from the infographics that were recently published by UNEP. A focus on the top performing countries can be more engaging content. Expected impact: 2.5 million readers. \n\n\
          2. Progress in Climate Action Post- COP28: Discussions could focus on the advancements made since the Dubai Consensus at COP28, particularly in moving away from fossil fuels and the establishment of the Loss & Damage Fund.Expected impact: 2.2 million readers. \n\n\
          3. Energy Transition and Sustainable Mining: UNEP's work in promoting responsible mining and sustainable minerals and metals use, which are crucial for scaling up renewable energy and electric vehicles, would be a pertinent topic. Expected impact: 2.1 million readers. \n\n\
          4. Desertification, Drought, and Land Restoration: Highlighting efforts in combating desertification and drought, as well as proactive land restoration projects, such as those observed in Saudi Arabia. Expected impact: 2 million readers. \n\n\
          5. Plastic Pollution: Articles could delve into the development of an international legally binding instrument on plastic pollution, particularly focusing on marine environments, as part of the resolutions from UNEA-5.2. Expected impact: 2 million readers. \n\n\
          6. Global Environmental Rule of Law: The role of the Montevideo Environmental Law Programme in promoting the development and implementation of environmental rule of law and its contribution to the environmental dimension of the 2030 Agenda. Expected impact: 1.2 million readers. \n\n\
          7. Science-Policy Panel on Chemicals and Waste: Covering the work of the Open Ended Working Group to finalize proposals for a Science-Policy Panel focused on sound chemical management and pollution prevention. Expected impact: 1.1 million readers. \n\n\
          8. Antimicrobial Resistance (AMR): Discussing the 2024 United Nations General Assembly High-level Meeting on AMR and its global implications on healthcare and mortality rates. Expected impact: 1.1 million readers. \n\n\
          9. Biodiversity Conservation Efforts: The 16th Conference of the Parties to the Convention on Biological Diversity, focusing on global efforts to conserve biodiversity, biosafety, and access to genetic resources. Expected impact: 1 million readers. \n\n\
          10. Methane Emissions Reduction: Highlighting UNEP's initiatives such as the International Methane Emissions Observatory and the Climate and Clean Air Coalition's Global Methane Assessment in reducing methane emissions. Expected impact: 0.9 million readers. \n\n 11. Nature-based Solutions and Community Resilience: Exploring UNEP's work on Blue Forests Solutions and the influence of blue carbon in Nationally Determined Contributions(NDCs), as well as projects for ecosystem restoration benefiting communities.Expected impact: 0.9 million readers.\n\n",
        type: "text",
      },
      { content: "resource_1.png", type: "image_file" },
      { content: "resource_2.png", type: "image_file" },
      { content: "resource_3.png", type: "image_file" },
    ],
    sources: [],
    usage: [],
  };

  const rfpAnswers = [
    {
      question:
        "What CRM capabilities do you offer in maintaining the set-up of, and relations with external managers?",
      answer: [
        {
          content:
            "Our CRM system offers tools for managing relations with external managers, including tracking interactions, storing relevant documents, and scheduling follow-ups. The system ensures that all communications and engagements are logged and easily accessible. The system also provides customizable dashboards to monitor and report on the status of external manager relationships.[1][2]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "Regarding your question about our CRM capabilities: Yes, the CRM facilitates tracking and managing external manager relationships efficiently. It includes features for scheduling follow-ups, ensuring all communications are captured and readily available for access.",
          uuid: "1",
          source_path: "Questions PFM proposal",
          last_updated: "Wed, 14 Dec 2023 07:00:00 GMT",
          type: "email",
        },
        {
          extract:
            "The CRM system provides: \n- Tracking of interactions\n- Storage of relevant documents\n- Scheduling of follow-ups\n- Customizable dashboards for monitoring and reporting",
          uuid: "2",
          source_path: "CRM System Notes",
          last_updated: "Mon, 28 Jun 2023 07:00:00 GMT",
          type: "sharepoint",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "What CRM functionalities do you provide for setting up and managing relationships with external managers?",
          answer:
            "Our Customer Relationship Management (CRM) system is equipped with capabilities specifically designed to manage interactions with external managers. This includes functionality for tracking communicatiFons, archiving essential documents, and scheduling subsequent engagements. Our system guarantees that every interaction and commitment is thoroughly documented and readily retrievable. Additionally, it features adaptable dashboards that allow for the effective monitoring and reporting of the status of relationships with external managers.",
          vendor: "XYZ",
          source_path:
            "Section II - Scope of Services, External Manager Relations",
          contract_date: "Tue, 20 Jun 2023 07:00:00 GMT",
        },
      ],
    },
    {
      question:
        "What other systems does the CRM sync contacts, emails, and calendars with?",
      answer: [
        {
          content:
            "Our CRM system syncs contacts, emails, and calendars with Microsoft Outlook, Google Workspace, and other major email platforms to ensure seamless integration and data consistency.[1] Additionally, it integrates with third-party calendaring and contact management systems for enhanced interoperability.[2]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "To confirm, our CRM also supports integration with various third-party calendaring and contact management systems, boosting our interoperability.",
          uuid: "1",
          source_path: "#crm-updates - Alex H.",
          last_updated: "Fri, 14 Apr 2024 07:00:00 GMT",
          type: "slack",
        },
        {
          extract:
            "Enhanced interoperability is supported through integration with third-party calendaring and contact management systems, complementing our core integrations with major email services like Microsoft Outlook and Google Workspace.",
          uuid: "2",
          source_path: "CRM System Integration Overview",
          last_updated: "Wed, 28 Dec 2023 07:00:00 GMT",
          type: "sharepoint",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "What other platforms does the CRM connect with to share contacts, emails, and calendar data?",
          answer:
            "Our CRM system enhances interoperability by integrating seamlessly with major email platforms, including Microsoft Outlook and Google Workspace, as well as with third-party calendaring and contact management systems. This ensures consistent data across contacts, emails, and calendars, facilitating seamless integration.",
          vendor: "XYZ",
          source_path: "Section II - Scope of Services, Syncing Capabilities",
          contract_date: "Mon, 20 Mar 2023 07:00:00 GMT",
        },
      ],
    },
    {
      question: "Describe your contact system search capabilities.",
      answer: [
        {
          content:
            "Our contact system features robust search capabilities, allowing users to search by name, date, keyword, and other custom fields. The system supports text search within PDF documents and emails, ensuring comprehensive search results.[1]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "Contact Search Functunality\n- Search by Name: by full name or partial name entries.\n- Search by Date: Filter results by specific dates or date ranges.\n- Search by Keyword: Use keywords to find relevant contacts and associated data (fuzzy matching).\n- Custom Search: User defined search parameters to include fields specific to meet specific needs.\n- Supported File Types: PDF, Emails",
          uuid: "1",
          source_path: "CRM User Guide",
          last_updated: "Wed, 28 Mar 2024 07:00:00 GMT",
          type: "sharepoint",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "Detail the capabilities of your system for searching contacts.",
          answer:
            "Our system empowers users with advanced search capabilities, ensuring comprehensive results. Users can perform searches using various criteria such as name, date, keyword, and other customizable fields. Additionally, the system is equipped to handle text searches within PDF documents and emails, enhancing the thoroughness of searches.",
          vendor: "XYZ",
          source_path: "Section II - Scope of Services, Search Capabilities",
          contract_date: "Fri, 20 Jun 2023 07:00:00 GMT",
        },
      ],
    },
    {
      question:
        "Describe how data can be entered into your system (bulk uploads/downloads and one-off upload/download).",
      answer: [
        {
          content:
            "Our system allows data entry through bulk uploads/downloads using CSV, Excel, and other file formats.[1] It also supports one-off uploads/downloads via an intuitive interface for individual records. The system ensures data integrity and provides validation checks during the upload process.[1][2][3]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "Bulk Upload/Download:\n- Supports various file formats including CSV, Excel, etc.\n- Designed for efficient handling of large data sets.\nData Integrity:\n- Built-in validation checks during the upload process.\n-Ensures accuracy and consistency of data.",
          uuid: "1",
          source_path: "Data ingestion questions - Greg S.",
          last_updated: "Tue, 28 Jan 2023 07:00:00 GMT",
          type: "email",
        },
        {
          extract:
            "For individual data entries, the platform offers a user interface, allowing for one-off uploads and downloads. This feature is particularly useful for managing smaller datasets and individual records.",
          uuid: "2",
          source_path: "CRM Capabilities - Data Handling",
          last_updated: "Thu, 21 Sep 2023 07:00:00 GMT",
          type: "sharepoint",
        },
        {
          extract:
            "The system allows for bulk uploads/downloads of data using various file formats like CSV and Excel. It also support one-off data entry through a user-friendly interface with built-in validation.",
          uuid: "3",
          source_path: "CRM capabilities: Upload/Download Features",
          last_updated: "Mon, 14 Jun 2023 07:00:00 GMT",
          type: "sharepoint",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "Describe how data can be entered into your system (bulk uploads/downloads and one-off upload/download).",
          answer:
            "Our system allows data entry through bulk uploads/downloads using CSV, Excel, and other file formats. It also supports one-off uploads/downloads via an intuitive interface for individual records. The system ensures data integrity and provides validation checks during the upload process.",
          vendor: "XYZ",
          source_path: "Section II - Scope of Services, Data Entry",
          contract_date: "Mon, 20 Jul 2023 07:00:00 GMT",
        },
      ],
    },
    {
      question:
        "What tools does the system offer for maintaining research, searchable notes, tracking meetings, follow-ups, etc.? What document formats does the system support?",
      answer: [
        {
          content:
            "Our system offers tools for maintaining research with searchable notes, tracking meetings, and follow-ups. It supports various document formats including Word, Excel, PDF, and more. Users can easily create, search, and manage research notes and documents within the system.[1][2]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "Hi Janet,\nIn response to your query, our system accommodates multiple document formats like Word, Excel, and PDF. And can easily, be extended to other types.",
          uuid: "1",
          source_path: "CRM Research Module Clarification - Howard L.",
          last_updated: "Fri, 14 Jun 2024 07:00:00 GMT",
          type: "email",
        },
        {
          extract:
            "Document Support and Management:\nVersatile Document Compatibility: Supports a variety of Word, Excel, PDF, and TXT formats.\nComprehensive Document Handling: Facilitates creation, searching, and management of research notes and documents.\nCustomizable Templates: Offers templates for notes and documents, allowing customization to meet specific needs and preferences.",
          uuid: "2",
          source_path: "CRM Features Overview",
          last_updated: "Wed, 28 Nov 2023 07:00:00 GMT",
          type: "sharepoint",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "What features does the system provide for managing research, tracking meetings, scheduling follow-ups, and creating searchable notes? ",
          answer:
            "Our platform provides a robust suite of tools that allow users to create, manage, and search research notes and documents. It is designed to facilitate the maintenance of research through searchable notes and efficiently track meetings and follow-up actions.",
          vendor: "XYZ",
          source_path: "Section II - Scope of Services, Research Management",
          contract_date: "Thu, 20 Dec 2023 07:00:00 GMT",
        },
      ],
    },
    {
      question:
        "What third-party systems does the research management portion of the software sync with?",
      answer: [
        {
          content:
            "The research management portion of our software syncs with Bloomberg, FactSet, and other major financial data providers to ensure comprehensive data integration.[1]",
          type: "text",
          lastUpdated: "",
        },
      ],
      sources: [
        {
          extract:
            "The system integrates with the a comprehensive set of third-party financial data systems: Bloomberg, FactSet, S&P Global Market Intelligence, Thomson Reuters (Refinitiv), S&P Global Market Intelligence, Morningstar, MSCI, and Moody's Analytics",
          uuid: "1",
          source_path: "CRM System Integration Overview",
          last_updated: "Thu, 14 Apr 2024 07:00:00 GMT",
          input: "sharepoint",
        },
        {
          extract:
            "I can confirm that our users primarily use the Bloomberg and FactSet integration for their research generation. Third, would be Reuters.",
          uuid: "1",
          source_path: "#crm-research-module - Jennifer B.",
          last_updated: "Thu, 14 Apr 2024 07:00:00 GMT",
          input: "slack",
        },
      ],
      usage: [],
      pastRFPs: [
        {
          uuid: "RFP-110234ASVD",
          question:
            "Which external systems are compatible with the research management module of the software?",
          answer:
            "Our software's research management component integrates seamlessly with leading financial data providers like Bloomberg and FactSet, along with other finance resources, to ensure comprehensive data synchronization.",
          vendor: "XYZ",
          source_path: "Section II - Scope of Services, Research Integration",
          contract_date: "Wed, 20 Feb 2024 07:00:00 GMT",
        },
      ],
    },
    // {
    //   question:
    //     "Does the system create any standard reports, for example, manager 'tear sheets' or 'memos' to provide a consolidated view of key manager details based on internal commentary?",
    //   answer: [
    //     {
    //       content:
    //         "Yes, our system creates standard reports such as manager 'tear sheets' and 'memos' to provide a consolidated view of key manager details based on internal commentary.[1] These reports can be customized to include specific data fields as required.[2][3]",
    //       type: "text",
    //       lastUpdated: "",
    //     },
    //   ],
    //   sources: [
    //     {
    //       extract:
    //         "The system must create standard reports, including manager 'tear sheets' and 'memos', to provide a consolidated view of key manager details based on internal commentary. Customization of these reports to include specific data fields should be supported.",
    //       uuid: "1",
    //       source_path: "Section II - Scope of Services, Standard Reports",
    //       last_updated: "Wed, 14 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "Our CRM system supports the creation of standard reports such as manager 'tear sheets' and 'memos'. These reports consolidate key manager details and can be customized to meet specific requirements.",
    //       uuid: "2",
    //       source_path: "Section II - CRM Capabilities, Standard Reporting",
    //       last_updated: "Wed, 21 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "The system must provide tools for creating standard reports like manager 'tear sheets' and 'memos', consolidating key manager details. It should support customization to include specific data fields.",
    //       uuid: "3",
    //       source_path: "Section II - Scope of Services, Reporting Tools",
    //       last_updated: "Wed, 28 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    //   usage: [],
    //   pastRFPs: [
    //     {
    //       uuid: "RFP-110234ASVD",
    //       question:
    //         "Does the system create any standard reports, for example, manager 'tear sheets' or 'memos' to provide a consolidated view of key manager details based on internal commentary?",
    //       answer:
    //         "Yes, our system creates standard reports such as manager 'tear sheets' and 'memos' to provide a consolidated view of key manager details based on internal commentary. These reports can be customized to include specific data fields as required.",
    //       vendor: "XYZ",
    //       source_path: "Section II - Scope of Services, Standard Reports",
    //       contract_date: "Wed, 20 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    // },
    // {
    //   question:
    //     "How many custom columns can a firm add? What are the limitations of those columns?",
    //   answer: [
    //     {
    //       content:
    //         "Our system allows firms to add an unlimited number of custom columns.[2] There are no specific limitations on these columns, enabling extensive customization based on user needs. Each column can be tailored to capture specific data points relevant to the firm's operations.[1]",
    //       type: "text",
    //       lastUpdated: "",
    //     },
    //   ],
    //   sources: [
    //     {
    //       extract:
    //         "The system must allow for an unlimited number of custom columns without specific limitations, enabling extensive customization based on user needs. Each column can capture specific data points relevant to the firm's operations.",
    //       uuid: "1",
    //       source_path: "Section II - Scope of Services, Custom Columns",
    //       last_updated: "Wed, 14 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "Our CRM allows for the addition of an unlimited number of custom columns, with no specific limitations. This enables extensive customization to capture relevant data points for the firm.",
    //       uuid: "2",
    //       source_path: "Section II - CRM Capabilities, Customization",
    //       last_updated: "Wed, 21 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    //   usage: [],
    //   pastRFPs: [
    //     {
    //       uuid: "RFP-110234ASVD",
    //       question:
    //         "How many custom columns can a firm add? What are the limitations of those columns?",
    //       answer:
    //         "Our system allows firms to add an unlimited number of custom columns. There are no specific limitations on these columns, enabling extensive customization based on user needs. Each column can be tailored to capture specific data points relevant to the firm's operations.",
    //       vendor: "XYZ",
    //       source_path: "Section II - Scope of Services, Custom Columns",
    //       contract_date: "Wed, 20 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    // },
    // {
    //   question:
    //     "Does the system allow for external managers to upload documents/data that contain confidential information? If so, how is confidentiality managed?",
    //   answer: [
    //     {
    //       content:
    //         "Yes, our system allows external managers to upload documents/data containing confidential information.[1] Confidentiality is managed through secure encryption, access controls, and regular audits. The system ensures that only authorized users can access sensitive information, maintaining strict data privacy standards.[1][2]",
    //       type: "text",
    //       lastUpdated: "",
    //     },
    //   ],
    //   sources: [
    //     {
    //       extract:
    //         "The system must allow external managers to upload documents/data containing confidential information. Confidentiality is managed through secure encryption, access controls, and regular audits to ensure only authorized users can access sensitive information.",
    //       uuid: "1",
    //       source_path:
    //         "Section II - Scope of Services, Confidential Information Management",
    //       last_updated: "Wed, 14 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "Our CRM supports the secure upload of confidential documents/data by external managers. It employs encryption, access controls, and regular audits to protect sensitive information and ensure compliance with data privacy standards.",
    //       uuid: "2",
    //       source_path:
    //         "Section II - CRM Capabilities, Confidential Data Management",
    //       last_updated: "Wed, 21 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    //   usage: [],
    //   pastRFPs: [
    //     {
    //       uuid: "RFP-110234ASVD",
    //       question:
    //         "Does the system allow for external managers to upload documents/data that contain confidential information? If so, how is confidentiality managed?",
    //       answer:
    //         "Yes, our system allows external managers to upload documents/data containing confidential information. Confidentiality is managed through secure encryption, access controls, and regular audits. The system ensures that only authorized users can access sensitive information, maintaining strict data privacy standards.",
    //       vendor: "XYZ",
    //       source_path:
    //         "Section II - Scope of Services, Confidential Information Management",
    //       contract_date: "Wed, 20 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    // },
    // {
    //   question:
    //     "Does the system allow for the creation of templates/forms? Does it allow for sending out automated emails to managers and then tabulating responses in a report format for regular/standard requests? Can those forms allow for attachments to be added to those email requests?",
    //   answer: [
    //     {
    //       content:
    //         "Yes, our system allows for the creation of templates/forms and supports sending automated emails to managers. It can tabulate responses in a report format for regular/standard requests and allows for attachments to be included in those email requests.[1][2][3]",
    //       type: "text",
    //       lastUpdated: "",
    //     },
    //   ],
    //   sources: [
    //     {
    //       extract:
    //         "The system must support the creation of templates/forms and allow for sending automated emails to managers. It should also be able to tabulate responses in a report format and include attachments in email requests.",
    //       uuid: "1",
    //       source_path:
    //         "Section II - Scope of Services, Template and Email Management",
    //       last_updated: "Wed, 14 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "Our CRM enables users to create templates/forms and send automated emails to managers. It supports the tabulation of responses in a report format and allows attachments to be included in email requests.",
    //       uuid: "2",
    //       source_path:
    //         "Section II - CRM Capabilities, Email and Template Management",
    //       last_updated: "Wed, 21 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "The system must allow for the creation of customizable templates/forms and support automated email distribution. It should enable the tabulation of responses and include attachments in email requests.",
    //       uuid: "3",
    //       source_path:
    //         "Section II - Scope of Services, Automated Email Features",
    //       last_updated: "Wed, 28 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    //   usage: [],
    //   pastRFPs: [
    //     {
    //       uuid: "RFP-110234ASVD",
    //       question:
    //         "Does the system allow for the creation of templates/forms? Does it allow for sending out automated emails to managers and then tabulating responses in a report format for regular/standard requests? Can those forms allow for attachments to be added to those email requests?",
    //       answer:
    //         "Yes, our system allows for the creation of templates/forms and supports sending automated emails to managers. It can tabulate responses in a report format for regular/standard requests and allows for attachments to be included in those email requests.",
    //       vendor: "XYZ",
    //       source_path:
    //         "Section II - Scope of Services, Template and Email Management",
    //       contract_date: "Wed, 20 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    // },
    // {
    //   question:
    //     "Does the system allow for tagging investments with specific, and customized key words?",
    //   answer: [
    //     {
    //       content:
    //         "Yes, our system allows for tagging investments with specific and customized key words. This feature enables users to categorize and retrieve investment information efficiently.[1][2][3]",
    //       type: "text",
    //       lastUpdated: "",
    //     },
    //   ],
    //   sources: [
    //     {
    //       extract:
    //         "The system must support the tagging of investments with specific and customized key words to enable efficient categorization and retrieval of investment information.",
    //       uuid: "1",
    //       source_path: "Section II - Scope of Services, Investment Tagging",
    //       last_updated: "Wed, 14 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "Our CRM allows users to tag investments with customized key words, facilitating efficient categorization and retrieval of relevant information.",
    //       uuid: "2",
    //       source_path: "Section II - CRM Capabilities, Tagging Features",
    //       last_updated: "Wed, 21 Jun 2023 07:00:00 GMT",
    //     },
    //     {
    //       extract:
    //         "The system must enable the tagging of investments with specific key words that can be customized by the user, allowing for effective categorization and easy retrieval of investment data.",
    //       uuid: "3",
    //       source_path: "Section II - Scope of Services, Custom Tagging",
    //       last_updated: "Wed, 28 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    //   usage: [],
    //   pastRFPs: [
    //     {
    //       uuid: "RFP-110234ASVD",
    //       question:
    //         "Does the system allow for tagging investments with specific, and customized key words?",
    //       answer:
    //         "Yes, our system allows for tagging investments with specific and customized key words. This feature enables users to categorize and retrieve investment information efficiently.",
    //       vendor: "XYZ",
    //       source_path: "Section II - Scope of Services, Investment Tagging",
    //       contract_date: "Wed, 20 Jun 2023 07:00:00 GMT",
    //     },
    //   ],
    // },
  ];

  const fuse = new Fuse(rfpAnswers, fuseOptions);

  function searchRfpAnswers(searchText) {
    if (user.email === "admin@xpub.ai" || user.email === "kalyan@xpub.ai") {
      const results = fuse.search(searchText);

      if (results.length > 0) {
        return results[0].item;
      }
    }

    return null;
  }

  // NEXT TWO FUNCTIONS ARE FOR DZERO DEMO ONLY
  // Function to generate a deterministic time based on the file path
  const generateDeterministicTime = (path) => {
    let hash = 0;
    for (let i = 0; i < path.length; i++) {
      const char = path.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }

    const hours = Math.abs(hash) % 24;
    const minutes = Math.abs(hash >> 8) % 60;
    const seconds = Math.abs(hash >> 16) % 60;

    return { hours, minutes, seconds };
  };

  // Function to format date to required string format
  const formatDate = (date) => {
    return date.toUTCString();
  };

  const getDateFromUnepFile = async (path) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);

      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contents = response.data.contents;

      // First, try to find the date in the "Date: YYYY-MM-DD" format
      const dateRegex = /Date:\s*(\d{4}-\d{2}-\d{2})/;
      const dateMatch = contents.match(dateRegex);

      if (dateMatch && dateMatch[1]) {
        const [year, month, day] = dateMatch[1].split("-").map(Number);
        const { hours, minutes, seconds } = generateDeterministicTime(path);

        // Create date using UTC to avoid timezone issues
        const date = new Date(
          Date.UTC(year, month - 1, day, hours, minutes, seconds)
        );

        return formatDate(date);
      }

      throw new Error("Date not found in the expected format");
    } catch (err) {
      const { hours, minutes, seconds } = generateDeterministicTime(path);

      // Generate a deterministic date between 2021 and 2023
      const start = Date.UTC(2021, 0, 1);
      const end = Date.UTC(2023, 11, 31);
      const range = end - start;

      let hash = 0;
      for (let i = 0; i < path.length; i++) {
        const char = path.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }

      const timestamp = start + (Math.abs(hash) % range);
      const deterministicDate = new Date(timestamp);
      deterministicDate.setUTCHours(hours, minutes, seconds);

      return formatDate(deterministicDate);
    }
  };

  const askArchives = async (event) => {
    if (event.key === "Enter") {
      setIsSearchLoading(true);
      setQuestion("");
      setVideoUrl(null);
      setShowSearchDivContent(false);
      setShowSearchDiv(true);
      setAskArchivesError(false);
      setImageUrls([]);
      const searchText = event.target.value;
      setQuestion(searchText);
      try {
        if (
          user.email === "admin@xpub.ai" &&
          searchText.toLowerCase().startsWith("share some ideas")
        ) {
          const answer = ideaResponse.answer;
          if (answer && answer.length > 0) {
            let newImageURLs = {};
            answer.forEach((item, index) => {
              if (item.type === "image_file") {
                {
                  newImageURLs[item.content] = item.content;
                  setImageUrls((prev) => ({ ...prev, ...newImageURLs }));
                }
              }
            });
          }
          setTimeout(() => {
            setIsSearchLoading(false);
            setPassiveModalContent(ideaResponse);
            setShowSearchDivContent(true);
          }, 5000);
        } else {
          // call the search API and get results
          const requestData = {
            query: searchText,
            owner_ids: userArchiveIDs.map((item) => item.id),
            index_name: "archive-main",
          };
          const response = await api_external.post(
            env.salesPubAPI + "/archive-query",
            requestData
          );
          const results = response.data;
          const answer = results.answer;
          if (answer && answer.length > 0) {
            let newImageURLs = {};
            answer.forEach((item, index) => {
              if (item.type === "image_file") {
                fetchImage(item.content)
                  .then((url) => {
                    newImageURLs[item.content] = url;

                    setImageUrls((prev) => ({ ...prev, ...newImageURLs }));
                  })
                  .catch((error) =>
                    console.error("Error fetching image:", error)
                  );
              }
            });
          }
          setIsSearchLoading(false);
          // THIS NEEDS TO BE CHANGED (ONLY TEMP)
          if (
            user.email === "tester@xpub.ai" &&
            results.sources &&
            results.sources.length > 0
          ) {
            results.pastRFPs = [];
            await Promise.all(
              results.sources.map(async (source) => {
                source.last_updated = await getDateFromUnepFile(
                  source.source_path
                );
              })
            );
          }
          setPassiveModalContent(results);
          setShowSearchDivContent(true);
          api.post("/user/logAsyncServiceCall", {
            projectId: "archiveSearch",
            service_tag: "askArchives",
            service_parent_id: null,
            service_filename: null,
            options: {
              service_progress_status: false,
              service_payload: {
                input: { query: searchText },
                output: results,
              },
            },
            logs: response.data.usage,
          });
        }
      } catch (err) {
        api.post("/user/logAsyncServiceCall", {
          projectId: "archiveSearch",
          service_tag: "askArchives",
          service_parent_id: null,
          service_filename: null,
          options: {
            service_progress_status: false,
            service_error: true,
            service_error_msg: err?.message,
          },
        });
        setIsSearchLoading(false);
        setShowSearchDiv(false);
        setAskArchivesError(true);
      }
    }
  };

  function getFileNameFromPath(path, start_time) {
    // Extracts the file name from the path
    if (path !== undefined) {
      const fileId = String(path).split("/").pop();
      const foundItem = rowData.find((item) => item.id === fileId);
      return foundItem ? foundItem.name : fileId;
    } else return path;
  }

  const handleArchiveFile = async (path, start_time) => {
    try {
      const fileId = String(path).split("/").pop();
      let foundItem = rowData.find((item) => item.id === fileId);
      if (foundItem === undefined)
        foundItem = rowData.find((item) => item.name === path);
      const response = await api.post("file/download", null, {
        params: {
          id: foundItem.id,
          ownerId: foundItem.archiveId,
        },
      });
      const contentType = response.data.fileType;
      // let videoResponse = await api.get(
      //   `file/getSignedURL/655b4ced4acb190049016155`
      // );
      // setVideoUrl(videoResponse.data.url);
      // setShowVideoModal(true);
      // setStartTime("30");
      if (contentType.includes("video")) {
        setShowVideoModal(true);
        setVideoUrl(response.data.url);
        setStartTime(start_time);
      } else if (contentType.includes("pdf")) {
        const pdfUrl = response.data.url;
        window.open(pdfUrl, "_blank");
      } else {
        const contents = response.data.contents;
        var newTab = window.open();
        newTab.document.write(contents.replace(/\n/g, "<br>"));
        newTab.document.close();
      }
    } catch (err) {
      // console.log("Error:", err);
    }
  };

  const toggleArchiveConfiguration = (val) => {
    setArchiveConfiguration(val);
    getUserArchivesId();
  };

  const handleOnReady = (player) => {
    // Set the player ref
    playerRef.current = player;
  };

  const handleOnStart = () => {
    // Seek to the start time
    if (startTime && playerRef.current) {
      playerRef.current.seekTo(startTime, "seconds");
    }
  };
  const decodeHtml = (text) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = text;
    var txtValue = txt.value.replace(/\n/g, "<br />");
    return txtValue; // HERE MUST MAKE IT WORK WITH HTML
  };

  function processText(text) {
    const superscriptStyle = {
      fontSize: "var(--text-small)",
      color: "navy",
    };

    return text.split(/(\[\d+\])/g).map((part, index) => {
      if (/\[\d+\]/.test(part)) {
        return (
          <sup key={index} style={superscriptStyle}>
            {part}
          </sup>
        );
      }
      return part;
    });
  }

  async function fetchImage(fileId) {
    try {
      const response = await fetch(
        `https://api.openai.com/v1/files/${fileId}/content`,
        {
          headers: {
            Authorization:
              "Bearer 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",
          },
        }
      );
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      return url;
    } catch (error) {
      console.error("Error fetching image:", error);
    }
  }

  const handleDislike = async () => {
    setFeedbackModalState(true);
  };

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleSubmitFeedback = () => {
    api.post("feedback/storeFeedback", {
      query: question,
      generated_answer: passiveModalContent,
      feedback: dislike,
    });
    setDislike("");
    setFeedbackModalState(false);
  };

  const handleAccordionClick = (event) => {
    const buttonElement = event.currentTarget.querySelector(
      "button[aria-controls]"
    );
    const contentId = buttonElement.getAttribute("aria-controls");
    setTimeout(() => {
      const scrollContainer = document.querySelector(".answer-div");
      if (scrollContainer) {
        const accordionItem = document.getElementById(contentId);
        const relativeTop =
          accordionItem.getBoundingClientRect().top -
          scrollContainer.getBoundingClientRect().top;
        scrollContainer.scrollTop = relativeTop + scrollContainer.scrollTop;
      }
    }, 100);
  };

  function capitalizeFirstLetter(string) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderIconByType = (type) => {
    switch (type) {
      case "email":
        return <Email style={{ marginRight: "2%" }} />;
      case "slack":
        return <LogoSlack style={{ marginRight: "2%" }} />;
      case "sharepoint":
        return <ManagedSolutions style={{ marginRight: "2%" }} />;
      default:
        return <Document style={{ marginRight: "2%" }} />;
    }
  };

  return (
    <div>
      {isArchiveConfigured && (
        <div style={{ margin: "1.5rem 2rem" }}>
          <div className="page-header">
            <div className="page-title-container" style={{ marginTop: "0.7rem" }}>
              <Typography variant="pageHeading">Library</Typography>
              {/* <Button
                onClick={() => {
                  navigate("/archive-files");
                }}
                kind="primary"
                className="create-project-button"
              >
                Archive Files
              </Button> */}
            </div>
          </div>

          <Grid fullWidth>
            {" "}
            <Column xlg={16} lg={16} md={8} sm={4}>
              {" "}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "3rem",
                }}
              >
                <div
                  style={{
                    height: "70vh",
                    overflowY: "auto",
                    padding: "1rem",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignContent: "center",
                  }}
                >
                  {isSearchLoading && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Loader type="ball-grid-pulse" />
                      <br />
                      <p>Fetching answer from your archives...</p>
                    </div>
                  )}
                  {rowData === null && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <p>No files in the archives.</p>
                    </div>
                  )}
                  {askArchivesError && (
                    <div style={{ margin: "0 14% 0 14%" }}>
                      <div
                        style={{
                          display: "flex",
                          alignContent: "center",
                          justifyContent: "center",
                          gap: "10px",
                        }}
                      >
                        <ErrorFilled style={{ fill: "red" }} />
                        <span
                          style={{
                            fontSize: "var(--text-medium)",
                            fontWeight: "400",
                            alignContent: "center",
                            justifyContent: "center",
                          }}
                        >
                          Unable to fetch answer from archives. Please contact
                          support@xpub.ai
                        </span>
                      </div>
                    </div>
                  )}
                  {showSearchDivContent && (
                    <div
                      style={{
                        margin: "0 14% 0 14%",
                      }}
                      className="answer-div"
                    >
                      <h2
                        style={{
                          fontSize: "var(--text-medium)",
                          fontWeight: "500",
                          marginBottom: "1%",
                        }}
                      >
                        {capitalizeFirstLetter(question)}
                      </h2>
                      {passiveModalContent.answer.map((item, index) => {
                        if (item.type === "text") {
                          return (
                            <>
                              <p
                                style={{
                                  flex: "0 0 auto",
                                  fontSize: "var(--text-medium)",
                                  fontWeight: "400",
                                  textAlign: "justify",
                                  whiteSpace: "pre-line",
                                }}
                                key={index}
                              >
                                {processText(item.content)}
                              </p>
                              <div
                                className="archive-button"
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  marginBottom: "1%",
                                  marginTop: "1%",
                                  marginLeft: "90%",
                                }}
                              >
                                <Button
                                  onClick={handleDislike}
                                  iconDescription="Unhelpful"
                                  renderIcon={ThumbsDown}
                                  hasIconOnly
                                />
                                <Button
                                  onClick={() => handleCopy(item.content)}
                                  iconDescription="Copy"
                                  renderIcon={Copy}
                                  hasIconOnly
                                />
                                {process.env.REACT_APP_ENV === "staging" && (
                                  <Button
                                    onClick={() => handleCopy(item.content)}
                                    iconDescription="Upload to project"
                                    renderIcon={Upload}
                                    hasIconOnly
                                  />
                                )}
                              </div>
                            </>
                          );
                        } else if (item.type === "image_file") {
                          const imageUrl = image_urls[item.content];
                          return imageUrl ? (
                            <>
                              <img
                                style={{ margin: " 2% 0 2% 5%" }}
                                width="80%"
                                src={imageUrl}
                                alt={`Image ${index}`}
                                key={index}
                              />
                            </>
                          ) : (
                            <Loading withOverlay={false} small={true} />
                          );
                        }
                      })}

                      {passiveModalContent.sources &&
                        passiveModalContent.sources.length > 0 && (
                          <div>
                            <h2
                              style={{
                                fontSize: "var(--text-medium)",
                                fontWeight: "650",
                                marginBottom: "1%",
                                marginTop: "1%",
                                // paddingLeft: "10px",
                              }}
                            >
                              Sources
                            </h2>
                            <Accordion size="sm" className="sources">
                              {passiveModalContent.sources.map(
                                ({
                                  extract,
                                  source_path,
                                  uuid,
                                  start_time,
                                  last_updated,
                                  type,
                                }) => (
                                  <AccordionItem
                                    title={
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          paddingRight: "1rem",
                                        }}
                                      >
                                        <span>{`[${uuid}] ${extract.substring(
                                          0,
                                          75
                                        )}...`}</span>
                                        {last_updated && (
                                          <span
                                            style={{
                                              marginLeft: "10px",
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                            }}
                                            title="Last updated date"
                                          >
                                            <Time />
                                            <span
                                              style={{
                                                fontSize: "var(--text-small)",
                                              }}
                                              title="Last updated date"
                                            >
                                              {new Date(
                                                last_updated
                                              ).toLocaleDateString("en-US", {
                                                weekday: "short",
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                              })}
                                            </span>
                                          </span>
                                        )}
                                      </div>
                                    }
                                    key={uuid}
                                    onClick={handleAccordionClick}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginTop: "2%",
                                        marginLeft: "5%",
                                        marginRight: "2%",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontSize: "var(--text-medium)",
                                          fontWeight: "400",
                                        }}
                                        dangerouslySetInnerHTML={{
                                          __html: decodeHtml(extract),
                                        }}
                                      ></p>
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "2%",
                                          marginBottom: "1.5%",
                                        }}
                                      >
                                        {renderIconByType(type)}
                                        <span
                                          title={getFileNameFromPath(
                                            source_path
                                          )}
                                          style={{
                                            marginRight: "auto",
                                            fontSize: "var(--text-medium)",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            maxWidth: "70%",
                                            display: "inline-block",
                                          }}
                                        >
                                          {getFileNameFromPath(source_path)}
                                        </span>
                                        <Launch
                                          onClick={() =>
                                            handleArchiveFile(
                                              source_path,
                                              start_time
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </AccordionItem>
                                )
                              )}
                            </Accordion>
                          </div>
                        )}
                      {passiveModalContent.sources &&
                        passiveModalContent.sources.length > 0 &&
                        passiveModalContent.pastRFPs && (
                          <div>
                            <h2
                              style={{
                                fontSize: "var(--text-medium)",
                                fontWeight: "650",
                                marginBottom: "1%",
                                marginTop: "5rem",
                              }}
                            >
                              Past RFPs
                            </h2>
                            {passiveModalContent.pastRFPs.length > 0 ? (
                              <Accordion size="sm" className="sources">
                                {passiveModalContent.pastRFPs.map(
                                  ({
                                    question,
                                    answer,
                                    source_path,
                                    uuid,
                                    start_time,
                                    contract_date,
                                  }) => (
                                    <AccordionItem
                                      title={
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            paddingRight: "1rem",
                                          }}
                                        >
                                          <span>
                                            {uuid} - {source_path}
                                          </span>
                                          <span
                                            style={{
                                              marginLeft: "10px",
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "0.5rem",
                                            }}
                                            title="RFP response date"
                                          >
                                            <Time />
                                            <span
                                              style={{
                                                fontSize: "var(--text-small)",
                                              }}
                                              title="RFP response date"
                                            >
                                              {new Date(
                                                contract_date
                                              ).toLocaleDateString("en-US", {
                                                weekday: "short",
                                                day: "2-digit",
                                                month: "short",
                                                year: "numeric",
                                              })}
                                            </span>
                                          </span>
                                        </div>
                                      }
                                      key={uuid}
                                      onClick={handleAccordionClick}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          marginTop: "2%",
                                          marginLeft: "5%",
                                          marginRight: "2%",
                                        }}
                                      >
                                        <p
                                          style={{
                                            fontSize: "var(--text-medium)",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {decodeHtml(question)}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "var(--text-medium)",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {decodeHtml(answer)}
                                        </p>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: "2%",
                                            marginBottom: "1.5%",
                                          }}
                                        >
                                          <Document
                                            style={{ marginRight: "2%" }}
                                          />
                                          <span
                                            title={getFileNameFromPath(
                                              source_path
                                            )}
                                            style={{
                                              marginRight: "auto",
                                              fontSize: "var(--text-medium)",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                              textOverflow: "ellipsis",
                                              maxWidth: "70%",
                                              display: "inline-block",
                                            }}
                                          >
                                            {getFileNameFromPath(source_path)}
                                          </span>
                                          <Launch
                                            onClick={() =>
                                              handleArchiveFile(
                                                source_path,
                                                start_time
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </AccordionItem>
                                  )
                                )}
                              </Accordion>
                            ) : (
                              <p
                                style={{
                                  fontSize: "var(--text-medium)",
                                  fontWeight: "400",
                                }}
                              >
                                No past RFPs available.
                              </p>
                            )}
                          </div>
                        )}
                      <Modal
                        open={isGenFeedbackModelOpen}
                        modalHeading="Help us improve"
                        primaryButtonText="Submit"
                        secondaryButtonText="Cancel"
                        onRequestSubmit={handleSubmitFeedback}
                        onRequestClose={() => {
                          setDislike("");
                          setFeedbackModalState(false);
                        }}
                        size="sm"
                      >
                        <TextArea
                          labelText="What did you dislike?"
                          value={dislike}
                          onChange={handleDislikeChange}
                        />
                      </Modal>
                      <Modal
                        open={showVideoModal}
                        size="md"
                        passiveModal={true}
                        onRequestClose={() => {
                          setVideoUrl(null);
                          setStartTime(null);
                          setShowVideoModal(false);
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            paddingTop: "56.25%", // Aspect ratio 16:9, adjust as needed
                            marginTop: "4%",
                            height: 0, // This collapses the div to the size of its absolutely positioned children
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <ReactPlayer
                            ref={playerRef}
                            url={videoUrl}
                            controls={true}
                            style={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                            onReady={handleOnReady}
                            onStart={handleOnStart}
                          />
                        </div>
                      </Modal>
                    </div>
                  )}{" "}
                </div>
                <div
                  style={{
                    bottom: 0,
                    width: "70%",
                    zIndex: 1000,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <SharePointConfig />
                    <Search
                      id="search-1"
                      size="lg"
                      placeholder="Ask me anything"
                      light={false}
                      onKeyPress={askArchives}
                      labelText=""
                      onClear={() => {
                        setShowSearchDiv(false);
                        setAskArchivesError(false);
                        setPassiveModalContent("");
                        setShowSearchDivContent(false);
                      }}
                      className="archive-search"
                      style={{
                        borderRadius: "var(--border-radius)",
                        border: "1px solid lightgrey",
                      }}
                      disabled={rowData === null}
                    />
                  </div>

                  {/* <Search
                    id="search-1"
                    size="lg"
                    placeholder="Ask Archives"
                    light={false}
                    onKeyPress={askArchives}
                    labelText=""
                    onClear={() => {
                      setShowSearchDiv(false);
                      setAskArchivesError(false);
                      setPassiveModalContent("");
                      setShowSearchDivContent(false);
                    }}
                    className="archive-search"
                    style={{
                      borderRadius: "var(--border-radius)",
                      border: "1px solid lightgrey",
                    }}
                    disabled={rowData === null}
                  /> */}
                </div>
              </div>
            </Column>
          </Grid>
        </div>
      )}
      {!isArchiveConfigured && (
        <CMSTiles
          setArchiveonfiguration={toggleArchiveConfiguration}
          showCMS={supportedCMS}
          noArchives={true}
        />
      )}
    </div>
  );
};

export default Archives;
