import JSZip from 'jszip';
import { api } from '../store';

export const stripExtension = (filename) => {
  return filename.substr(0, filename.lastIndexOf(".")) || filename;
};

export const downloadAndPrepareFiles = async (documentLink) => {
  try {
    const response = await api.get(`/file/downloadExternalFile?url=${encodeURIComponent(documentLink)}`, { responseType: 'arraybuffer' });

    const blob = new Blob([response.data], {
      type: response.headers['content-type']
    });
    const fileName = documentLink.split('/').pop();

    const isZip = fileName.toLowerCase().endsWith('.zip');

    if (isZip) {
      // Handle zip file
      const zip = new JSZip();
      const contents = await zip.loadAsync(blob);
      const files = [];

      for (const [fileName, fileData] of Object.entries(contents.files)) {
        if (!fileData.dir) {
          const fileBlob = await fileData.async('blob');
          const file = new File([fileBlob], fileName, {
            type: getFileType(fileName)
          });
          files.push({
            file,
            tag: 'RFX',
            status: 'edit'
          });
        }
      }

      if (files.length > 0) {
        files[0].tag = 'RFX';
        files.slice(1).forEach(file => file.tag = 'forms');
      }

      return files;
    } else {
      // Handle single file
      const file = new File([blob], fileName, {
        type: getFileType(fileName)
      });
      return [{
        file,
        tag: 'RFX',
        status: 'edit'
      }];
    }

  } catch (error) {
    console.error('Error preparing files:', error);
    throw new Error(`Failed to download file: ${error.message}`);
  }
};

export const getFileDetails = async (documentLink) => {
  try {
    const fileName = documentLink.split('/').pop();
    const isZip = fileName.toLowerCase().endsWith('.zip');

    // Helper function to get file type from name
    const getFileTypeFromName = (fileName) => {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
        case 'pdf':
          return 'PDF Document';
        case 'doc':
          return 'Word Document';
        case 'docx':
          return 'Word Document';
        case 'xls':
          return 'Excel Spreadsheet';
        case 'xlsx':
          return 'Excel Spreadsheet';
        case 'txt':
          return 'Text Document';
        case 'jpg':
        case 'jpeg':
          return 'JPEG Image';
        case 'png':
          return 'PNG Image';
        default:
          return 'Unknown Type';
      }
    };

    if (isZip) {
      const response = await api.get(`/file/downloadExternalFile?url=${encodeURIComponent(documentLink)}`, { responseType: 'arraybuffer' });

      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      });

      const zip = new JSZip();
      const contents = await zip.loadAsync(blob);
      const files = [];

      // Extract file names and types from zip
      for (const fileName of Object.keys(contents.files)) {
        if (!contents.files[fileName].dir) {
          // Get just the base filename without path
          const baseFileName = fileName.split('/').pop();

          files.push({
            fileName: baseFileName,
            fileType: getFileTypeFromName(baseFileName),
            // tag: 'forms',
            // status: 'edit'
          });
        }
      }

      return files;

    } else {
      // For single files
      return [{
        fileName,
        fileType: getFileTypeFromName(fileName),
        // tag: 'RFX',
        // status: 'edit'
      }];
    }

  } catch (error) {
    console.error('Error getting file details:', error);
    throw new Error(`Failed to get file details: ${error.message}`);
  }
};

const getFileType = (fileName) => {
  const extension = fileName.split('.').pop().toLowerCase();
  switch (extension) {
    case 'pdf':
      return 'application/pdf';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'txt':
      return 'text/plain';
    default:
      return 'application/octet-stream';
  }
};
