import React, { useState, useCallback, useEffect } from 'react';
import {
  Box, Typography, Paper, Button, LinearProgress, Dialog, DialogTitle, DialogContent, IconButton,
  List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { api } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { addFileWithDetails } from "../reduxstore/projectFilesSlice";

const DragDropFileUploader = ({ projectId, processProjectFile, updateItem, formFiles }) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [selectFileDialogOpen, setSelectFileDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const selectedItem = useSelector((state) => state.selectedItem);

  const handleFileSelect = (event) => {
    if (event.target.files && event.target.files[0]) {
      handleFile(event.target.files[0]);
    }
  };

  const handleExistingFileSelect = (fileId) => {
    updateItem("file_id", fileId);
    setSelectFileDialogOpen(false);
  };

  const handleFile = async (newFile) => {
    setFile(newFile);
    await uploadFile(newFile);
  };

  const simulateProgress = () => {
    setUploadProgress(0);
    const interval = setInterval(() => {
      setUploadProgress((oldProgress) => {
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 95);
      });
    }, 500);
    return interval;
  };

  const uploadFile = async (fileToUpload) => {
    setUploading(true);
    setUploadError('');
    const progressInterval = simulateProgress();

    try {
      const formData = new FormData();
      formData.append('files', fileToUpload);
      formData.append("ownerId", projectId);
      formData.append("fileTags[]", 'forms');

      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data[0].status !== 200) {
        throw new Error(response.data[0].message);
      }
      const filePath = response.data[0].filePath;
      await processProjectFile(filePath);
      updateItem("file_id", filePath);
      dispatch(addFileWithDetails({ name: fileToUpload.name, id: filePath, tags: ['forms'], contentType: fileToUpload.type }));
      setUploadProgress(100);
    } catch (error) {
      setUploadError(error.message);
    } finally {
      clearInterval(progressInterval);
      setUploading(false);
    }
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      handleFile(files[0]);
    }
  }, []);

  const handleUploadClick = () => {
    document.getElementById('file-upload-input').click();
  };

  const handleSelectClick = () => {
    setSelectFileDialogOpen(true);
  };

  useEffect(() => {
    if (uploadProgress === 100) {
      const timer = setTimeout(() => setUploadProgress(0), 1000);
      return () => clearTimeout(timer);
    }
  }, [uploadProgress]);

  return (
    <Box sx={{ height: 'inherit', display: 'flex', flexDirection: 'column' }}>
      <Paper
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          height: "inherit",
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px dotted',
          borderColor: isDragging ? 'primary.main' : 'grey.300',
          backgroundColor: isDragging ? 'action.hover' : 'background.paper',
          padding: 3,
          transition: 'all 0.3s ease',
        }}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <Typography sx={{
          mb: 2, color: "black", width: '100%',
          fontSize: "0.875rem", fontWeight: "500", justifyContent: "center",
          textAlign: "center"
        }}>
          Add your completed attachment for:
        </Typography>
        <Typography sx={{
          mb: 2, color: "black", width: '100%',
          fontSize: "0.875rem", fontWeight: "500", justifyContent: "center",
          textAlign: "center", color: "#666666"
        }}>
          {selectedItem.title}
        </Typography>

        {!uploading && (
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2, alignItems: 'center', paddingTop: "1rem" }}>
            <Button
              variant="contained"
              startIcon={<FileUploadRoundedIcon />}
              onClick={handleUploadClick}
            >
              Upload
            </Button>
            <Typography variant="body2" sx={{ color: "#666666" }}>
              or
            </Typography>
            <Button
              variant="contained"
              startIcon={<AttachFileRoundedIcon />}
              onClick={handleSelectClick}
            >
              Attach
            </Button>
          </Box>
        )}

        {uploading && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
              {uploadProgress < 100 ? 'Uploading...' : 'Upload complete!'}
            </Typography>
          </Box>
        )}

        {uploadError && (
          <Typography color="error" variant="primaryText" sx={{ mt: 2 }}>
            {uploadError}
          </Typography>
        )}
      </Paper>

      <Dialog
        open={selectFileDialogOpen}
        onClose={() => setSelectFileDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{
          fontSize: "1rem",
          lineHeight: 1.2,
        }}>
          Attach Document
          <IconButton
            onClick={() => setSelectFileDialogOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseRoundedIcon sx={{ fontSize: "1rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {formFiles.length === 0 ? (
            <Typography variant="primaryText" sx={{ py: 2 }}>
              No documents are available. All existing form documents are attached to other items.
            </Typography>
          ) : (
            <List>
              {formFiles.map((file) => (
                <ListItem
                  key={file.id}
                  button
                  onClick={() => handleExistingFileSelect(file.id)}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    borderRadius: '8px',
                  }}
                >
                  <ListItemIcon>
                    <InsertDriveFileIcon />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{
                    sx: {
                      fontSize: "0.875rem",
                      lineHeight: 1.2,
                      color: "#757575",
                      wordBreak: "break-word",
                      overflowWrap: "break-word",
                    },
                  }}
                    primary={file.name} />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
      </Dialog>

      <input
        accept=".pdf,.doc,.docx,.txt"
        style={{ display: 'none' }}
        id="file-upload-input"
        type="file"
        onChange={handleFileSelect}
      />
    </Box>
  );
};

export default DragDropFileUploader;