import React, { useState, useEffect, useRef } from "react";
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Button,
  TextInput,
  InlineNotification,
  Grid,
  Column,
  Modal,
  ModalBody,
  InlineLoading,
} from "@carbon/react";
import "./Settings.scss";
import { api, api_external } from "../store";
import { useNavigate, useLocation } from "react-router-dom";
import { TrashCan, CheckmarkFilled } from "@carbon/icons-react";
import PricingPage from "../Components/StripePricing";
import env from "../config";
import CaptureSettings from '../Components/rfp/CaptureSettings';

const SettingsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [user, setUser] = useState({});
  const [noArchiveConfigured, setNoArchivesConfigured] = useState(false);
  const [configuredArchives, setConfiguredArchives] = useState([]);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  const [showArchiveDeleteModal, setShowArchiveDeleteModal] = useState(false);
  const [disablePrimaryButton, setDisablePrimaryButton] = useState(false);
  const [disableSecondaryButton, setDisableSecondaryButton] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isFinal, setIsFinal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [formData, setFormData] = useState({
    industry: "",
    businessOfferings: "",
    icp: "",
  });

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [currentPassError, setCurrentPassError] = useState(
    "Value cannot be empty"
  );
  const [newPassError, setNewPassError] = useState("Value cannot be empty");
  const [isNewPassError, setPassErr] = useState(false);
  const [isPassError, setPassError] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState("");
  const [showPricingModal, setShowPricingModal] = useState(false);
  const timeoutId = useRef(null);
  const [businessProfile, setBusinessProfile] = useState({});

  useEffect(() => {
    setFormData({
      industry: user.industry || "",
      businessOfferings: user.businessOfferings || "",
      icp: user.icp || "",
    });
  }, [user]);

  const getConfiguredArchives = async () => {
    const response = await api.get("/archives/getUserArchivesId");
    if (response.data.archives.length > 0) {
      setNoArchivesConfigured(false);
      setConfiguredArchives(response.data.archives);
    } else {
      setNoArchivesConfigured(true);
      setConfiguredArchives([]);
    }
  };

  function convertArchiveTypes(archiveType) {
    switch (archiveType) {
      case "wordPress":
        return "WordPress Archive";
      case "googleDrive":
        return "Google Drive Archive";
      case "uploadFiles":
        return "Uploaded Files Archive";
      case "drupal":
        return "Drupal Archive";
      case "sharePoint":
        return "SharePoint Archive";
      default:
        return "Unknown Archive Type";
    }
  }

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateUserProfile(formData);
      const updatedUser = {
        ...user,
        ...formData,
      };

      // Immediately update the user state and show success message
      setUser(updatedUser);
      setShowSuccessMessage(true);
      setShowErrorMessage("");
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);

      // Proceed with background tasks without waiting for them
      // generateNews(updatedUser)
      //   .then((news) => {
      //     // Optionally handle news update in state if needed
      //     console.log("News generated:", news);
      //   })
      //   .catch((err) => console.error("Error generating news:", err));

      // generateIdeas(updatedUser)
      //   .then((ideas) => {
      //     // Optionally handle ideas update in state if needed
      //     console.log("Ideas generated:", ideas);
      //   })
      //   .catch((err) => console.error("Error generating ideas:", err));
    } catch (error) {
      setShowErrorMessage(error.message || "Failed to update profile");
      setShowSuccessMessage(false);
    } finally {
      setIsLoading(false);
    }
  };

  const updateUserProfile = async (updateObj) => {
    try {
      await api.put("/user/updateUserProfile", updateObj);
    } catch (error) {
      console.error("Error updating user profile:", error);
    }
  };

  const getUserProfile = async () => {
    let response = await api.get("user/getUserProfile");
    setUser(response.data);
    setFormData({
      industry: response.data.industry || "",
      businessOfferings: response.data.businessOfferings || "",
      icp: response.data.icp || "",
    });
    return response.data;
  };

  const getBusinessProfile = async () => {
    const response = await api.get("/organization/getOrgProfile");
    setBusinessProfile(response.data.org);
    return response.data;
  };

  useEffect(() => {
    getConfiguredArchives();
    getUserProfile();
    getBusinessProfile();
  }, []);

  useEffect(() => {
    return () => {
      // Clear the timeout if the component unmounts
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    if (tab === "billing") {
      setCurrentTab(2);
    } else if (tab === "archives") {
      setCurrentTab(1);
    } else if (tab === "profile") {
      setCurrentTab(0);
    }
  }, [location.search]);

  const handleLinkClick = (event, path) => {
    event.preventDefault();
    navigate(path);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (
      newPass === "" ||
      newPass.length < 6 ||
      confirmPass === "" ||
      confirmPass.length < 6 ||
      confirmPass !== newPass ||
      newPass === currentPass
    ) {
      if (newPass === "" || confirmPass === "") {
        setPassErr(true);
      }
      if (newPass.length < 6 || confirmPass.length < 6) {
        setPassErr(true);
        setNewPassError("Value cannot be less than 6 characters");
      }
      if (confirmPass !== newPass) {
        setPassErr(true);
        setNewPassError(
          "New Password and Confirm Password values do not match"
        );
      }
      if (newPass === currentPass) {
        setPassErr(true);
        setNewPassError(
          "New Password cannot be the same as the Current Password value"
        );
      }
      return;
    }
    try {
      e.preventDefault();
      const res = await api.post("user/changePassword", {
        currentPassword: currentPass,
        newPassword: newPass,
      });
      setShowSuccessMessage(true);
      setShowPasswordModal(false);
      setCurrentPass("");
      setNewPass("");
      setConfirmPass("");
      setTimeout(() => {
        removeToken();
        removeProfile();
        navigate("/login");
      }, 3000);
    } catch (err) {
      setShowErrorMessage(err?.response?.data?.message);
      console.log(err);
      console.log(err?.response?.data?.message);
      if (err?.response?.data?.message) {
        this.errorMessage = err?.response?.data?.message;
      }
    }
  };

  const handleRemoveArchive = async () => {
    setShowArchiveDeleteModal(true);
  };

  const handleArchiveConfirmDelete = async (archiveId) => {
    setIsLoading(true);
    setIsInitial(false);
    setDisablePrimaryButton(true);
    setDisableSecondaryButton(true);
    await api.delete("/archives/deleteArchiveById?archiveId=" + archiveId);
    setIsLoading(false);
    setIsFinal(true);

    setTimeout(() => {
      getConfiguredArchives();
      setShowArchiveDeleteModal(false);
    }, 3000);
  };

  const handleCaptureSettingsUpdate = async (settings) => {
    try {
      await api.put("/organization/updateOrgCaptureSettings", settings);
      return true;
    } catch (error) {
      console.error("Error updating capture settings:", error);
      throw new Error(error.response?.data?.message || "Failed to update settings");
    }
  };

  return (
    <div className="settings-page" style={{ margin: "1.5rem 2rem" }}>
      <div className="page-header">
        <div className="page-title-container">
          <span className="page-title" style={{ marginTop: "0.7rem" }}>
            xPub Settings
          </span>
        </div>
      </div>
      <Grid fullWidth>
        <Column lg={16} md={8} sm={4}>
          <Tabs
            selectedIndex={currentTab}
            onChange={({ selectedIndex }) => {
              setCurrentTab(selectedIndex);
            }}
          >
            <TabList aria-label="settings-tabs">
              <Tab>Profile</Tab>
              <Tab>Business profile</Tab>
              {/* <Tab>Archives</Tab> */}
              {/* <Tab>Billing</Tab> */}
            </TabList>

            <TabPanels>
              {/* <TabPanel>
                <div className="profile-settings">
                  <div className="account-info">
                    <div>
                      <p>
                        <strong>Name</strong>
                      </p>
                      <TextInput
                        id="name"
                        labelText=""
                        value={user ? user.fname + " " + user.lname : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <p>
                        <strong>Email</strong>
                      </p>
                      <TextInput
                        id="email"
                        labelText=""
                        value={user ? user.email : ""}
                        readOnly
                      />
                    </div>
                    
                    <div>
                      <p>
                        <strong>Industry</strong>
                      </p>
                      <TextInput
                        id="industry"
                        labelText=""
                        value={user ? user.industry : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <p>
                        <strong>Business Offerings</strong>
                      </p>
                      <TextInput
                        id="businessOfferings"
                        labelText=""
                        value={user ? user.businessOfferings : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <p>
                        <strong>Ideal Customer Profile</strong>
                      </p>
                      <TextInput
                        id="icp"
                        labelText=""
                        value={user ? user.icp : ""}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </TabPanel> */}
              <TabPanel>
                <div className="profile-settings">
                  <div className="account-info">
                    {showErrorMessage && (
                      <InlineNotification
                        kind="error"
                        title="Error"
                        lowContrast={true}
                        subtitle={showErrorMessage}
                        onClose={() => setShowErrorMessage("")}
                      />
                    )}
                    <div>
                      <p>
                        <strong>Name</strong>
                      </p>
                      <TextInput
                        id="name"
                        labelText=""
                        value={user ? user.fname + " " + user.lname : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <p>
                        <strong>Email</strong>
                      </p>
                      <TextInput
                        id="email"
                        labelText=""
                        value={user ? user.email : ""}
                        readOnly
                      />
                    </div>
                    <div>
                      <form onSubmit={handleFormSubmit}>
                        <div>
                          <p>
                            <strong>Industry</strong>
                          </p>
                          <TextInput
                            id="industry"
                            labelText=""
                            value={formData.industry}
                            onChange={handleInputChange}
                          />
                        </div>
                        {/* <div>
                          <p>
                            <strong>Business Offerings</strong>
                          </p>
                          <TextInput
                            id="businessOfferings"
                            labelText=""
                            value={formData.businessOfferings}
                            onChange={handleInputChange}
                          />
                        </div> */}
                        {/* <div>
                          <p>
                            <strong>Ideal Customer Profile</strong>
                          </p>
                          <TextInput
                            id="icp"
                            labelText=""
                            value={formData.icp}
                            onChange={handleInputChange}
                          />
                        </div> */}
                        <div style={{ marginTop: "2rem" }}>
                          <Button type="submit" disabled={isLoading}>
                            Update Profile
                          </Button>
                          {showSuccessMessage && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "10px",
                              }}
                            >
                              <CheckmarkFilled
                                style={{ color: "#24a148", fontSize: "20px" }}
                              />
                              <span
                                style={{
                                  marginLeft: "10px",
                                  color: "#24a148",
                                  fontSize: "14px",
                                }}
                              >
                                Profile updated successfully
                              </span>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <CaptureSettings
                  orgId={user.organizationId}
                  businessProfile={businessProfile}
                  onUpdateSettings={(settings) => handleCaptureSettingsUpdate(settings)}
                />
              </TabPanel>

              {/* <TabPanel>
                <div className="archives-settings">
                  <Grid>
                    <Column lg={10}>
                      <h5
                        style={{
                          fontSize: "var(--text-medium)",
                          fontWeight: "600",
                        }}
                      >
                        Configured Archives:
                      </h5>
                      {noArchiveConfigured && (
                        <h6 style={{ marginLeft: "2%", marginTop: "2%" }}>
                          No archives configured
                        </h6>
                      )}
                      {configuredArchives.map((archive, index) => (
                        <div
                          key={archive.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "1rem",
                            backgroundColor: "#f9f9f9",
                            padding: "10px",
                            border: "1px solid #e1e1e1",
                            borderRadius: "var(--border-radius)",
                            marginTop: "2%",
                            boxShadow: "0px 0px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h6>{convertArchiveTypes(archive.archiveType)}</h6>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <TrashCan
                              style={{ cursor: "pointer", marginLeft: "1rem" }}
                              onClick={() => handleRemoveArchive()}
                            />
                            <Modal
                              open={showArchiveDeleteModal}
                              modalHeading="Delete archive"
                              onRequestClose={() => {
                                setShowArchiveDeleteModal(false);
                                setIsInitial(true);
                                setIsLoading(false);
                                setIsFinal(false);
                                setDisablePrimaryButton(false);
                              }}
                              danger
                              primaryButtonText="Delete"
                              secondaryButtonText="Cancel"
                              onRequestSubmit={() =>
                                handleArchiveConfirmDelete(archive.id)
                              }
                              primaryButtonDisabled={disablePrimaryButton}
                              size="sm"
                            >
                              {isInitial && (
                                <ModalBody>
                                  Are you sure you want to delete this archive?
                                  This action cannot be undone.
                                </ModalBody>
                              )}
                              {isLoading && (
                                <InlineLoading
                                  description="Deleting archive"
                                  status="active"
                                />
                              )}
                              {isFinal && (
                                <ModalBody>
                                  Archive Deleted Successfully.
                                </ModalBody>
                              )}
                            </Modal>
                          </div>
                        </div>
                      ))}
                      <br></br>
                      {
                        <Button
                          kind="primary"
                          onClick={(event) =>
                            handleLinkClick(event, "/archive-add")
                          }
                        >
                          Add new archive
                        </Button>
                      }
                    </Column>
                  </Grid>
                </div>
              </TabPanel>

              <TabPanel>
                <div className="billing-settings">
                  <div className="current-plan">
                    <p>
                      Current Plan: <span>{user && user.plan}</span>
                    </p>

                    <Button
                      kind="primary"
                      onClick={() => setShowPricingModal(true)}
                    >
                      Upgrade Plan
                    </Button>
                  </div>

                  {user && user.plan === "free" && user.credits > 0 && (
                    <p>
                      Your profile has limited SEO content generation credits.
                      Once these credits are used up, you will no longer be able
                      to generate more SEO content. To continue generating
                      content, consider upgrading to a paid plan.
                    </p>
                  )}
                  {user && user.plan === "free" && user.credits === 0 && (
                    <p>
                      No free credits left. Upgrade your plan to start
                      generating SEO content.
                    </p>
                  )}
                </div>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </Column>
      </Grid>
      <Modal
        open={showPricingModal}
        onRequestClose={() => {
          setShowPricingModal(false);
        }}
        modalHeading=""
        passiveModal={true}
        size="lg"
      >
        <PricingPage user={user} />
      </Modal>
      <Modal
        open={showPasswordModal}
        modalHeading="Change Password"
        primaryButtonText="Update"
        secondaryButtonText="Cancel"
        onRequestSubmit={handlePasswordChange}
        onRequestClose={() => {
          setShowPasswordModal(false);
        }}
        size="sm"
      >
        {showErrorMessage && (
          <InlineNotification
            lowContrast
            kind="error"
            title={showErrorMessage}
          />
        )}
        <TextInput.PasswordInput
          id="currentPassword"
          labelText="Enter your current password"
          value={currentPass}
          invalid={isPassError}
          invalidText={currentPassError}
          onChange={(e) => {
            setPassError(false);
            setCurrentPass(e.target.value);
          }}
        />{" "}
        <br></br>
        <TextInput.PasswordInput
          id="newPassword"
          labelText="Enter New Password"
          value={newPass}
          invalid={isNewPassError}
          invalidText={newPassError}
          onChange={(e) => {
            setPassErr(false);
            setNewPass(e.target.value);
          }}
        />{" "}
        <br></br>
        <TextInput.PasswordInput
          id="confirmPassword"
          labelText="Confirm New Password"
          value={confirmPass}
          invalid={isNewPassError}
          invalidText={newPassError}
          onChange={(e) => {
            setPassErr(false);
            setConfirmPass(e.target.value);
          }}
        />
      </Modal>
    </div>
  );
};

export default SettingsPage;
