import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  TextField,
  Typography,
  Box,
  IconButton,
  Grid,
  Divider,
  Chip
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';

const OpportunityFeedbackDialog = ({ open, onClose, opportunity, onSubmit, user }) => {
  // Check for existing feedback from current user
  const userFeedback = opportunity.feedback?.find(f => f.userId === user._id);
  const [score, setScore] = useState(userFeedback?.score || opportunity.scoring.score);
  const [reasoning, setReasoning] = useState(userFeedback?.scoring_reasoning || '');
  const [showAnalysis, setShowAnalysis] = useState(false);

  const handleSubmit = () => {
    onSubmit({ score, reasoning });
    onClose();
    setScore(3);
    setReasoning('');
    setShowAnalysis(false);
  };

  const renderAnalysis = () => (
    <Box sx={{ mt: 2, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>Current Analysis</Typography>

      <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
        Score: {opportunity.scoring.score}/5
      </Typography>

      {opportunity.scoring.scoring_reasoning && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Analysis
          </Typography>
          <Typography variant="body2">
            {opportunity.scoring.scoring_reasoning}
          </Typography>
        </Box>
      )}

      {opportunity.scoring.pros?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Positives
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {opportunity.scoring.pros.map((pro, index) => (
              <Chip
                key={index}
                label={pro}
                size="small"
                color="success"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      )}

      {opportunity.scoring.cons?.length > 0 && (
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
            Negatives
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {opportunity.scoring.cons.map((con, index) => (
              <Chip
                key={index}
                label={con}
                size="small"
                color="error"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Scoring Feedback</Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="primaryText" gutterBottom>Score (1-5): What do you think is the right score for this opportunity?</Typography>
            <Slider
              value={score}
              onChange={(_, newValue) => setScore(newValue)}
              step={1}
              marks={[
                { value: 1, label: '1' },
                { value: 2, label: '2' },
                { value: 3, label: '3' },
                { value: 4, label: '4' },
                { value: 5, label: '5' }
              ]}
              min={1}
              max={5}
              valueLabelDisplay="off"
              sx={{ width: '50%' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="primaryText">Reasoning</Typography>
              <Button
                size="small"
                onClick={() => setShowAnalysis(!showAnalysis)}
              >
                {showAnalysis ? 'Hide Analysis' : 'Show Analysis'}
              </Button>
            </Box>
            <TextField
              multiline
              rows={4}
              fullWidth
              placeholder="Please provide your reasoning..."
              value={reasoning}
              onChange={(e) => setReasoning(e.target.value)}
              sx={{ fontSize: '0.875rem' }}
            />
          </Grid>
          {showAnalysis && (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              {renderAnalysis()}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!reasoning.trim() || score < 1}
        >
          Submit Feedback
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OpportunityFeedbackDialog;