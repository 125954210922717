import {
  GridToolbarColumnsButton,
  GridToolbarQuickFilter
} from "@mui/x-data-grid";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  Typography,
  Button,
  Divider
} from "@mui/material";
import React, { useState, useRef } from "react";
import CategoryIcon from '@mui/icons-material/Category';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import LabelIcon from '@mui/icons-material/Label';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import BlockIcon from '@mui/icons-material/Block';
import ListItemIcon from "@mui/material/ListItemIcon";

const StyledCheckbox = ({ ...props }) => (
  <Checkbox
    {...props}
    sx={{
      padding: '4px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
      }
    }}
  />
);

const CategoryFilter = ({ opportunities, onFilterChange, selectedCategories }) => {
  const [open, setOpen] = useState(false);
  const categories = [...new Set(opportunities.map(opp => opp.category))].sort();
  const anchorRef = useRef(null);

  const handleSelectAll = () => {
    onFilterChange(categories);
  };

  const handleSelectNone = (e) => {
    e.stopPropagation();
    onFilterChange([]);
  };

  const handleCategoryClick = (category, e) => {
    e.stopPropagation();
    const newSelection = selectedCategories.includes(category)
      ? selectedCategories.filter(c => c !== category)
      : [...selectedCategories, category];
    onFilterChange(newSelection);
  };

  const allSelected = categories.length === selectedCategories.length;
  const someSelected = selectedCategories.length > 0 && selectedCategories.length < categories.length;

  return (
    <FormControl size="small">
      <Button
        ref={anchorRef}
        onClick={() => setOpen(true)}
        startIcon={<CategoryIcon fontSize="small" sx={{ color: '#55828b' }} />}
        sx={{
          color: '#55828b',
          textTransform: 'none',
          borderRadius: '10px',
          fontSize: '0.8125rem',
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          }
        }}
      >
        Filter Categories
        {selectedCategories.length > 0 && (
          <Typography
            component="span"
            sx={{
              ml: 1,
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '10px',
              padding: '2px 8px',
              fontSize: '0.55rem'
            }}
          >
            {selectedCategories.length}
          </Typography>
        )}
      </Button>
      <Select
        multiple
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={selectedCategories}
        sx={{ display: 'none' }}
        MenuProps={{
          anchorEl: anchorRef.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              maxHeight: 500,
              maxWidth: 350,
              overflow: 'auto',
              boxShadow: 'none',
            }
          }
        }}
      >
        <MenuItem onClick={handleSelectAll}>
          <StyledCheckbox checked={allSelected} indeterminate={someSelected} />
          <ListItemText primaryTypographyProps={{
            sx: {
              fontSize: "0.875rem",
              lineHeight: 1,
              color: "#757575",
              whiteSpace: "normal",
              wordBreak: "break-word",
            },
          }} primary="Select All" />
        </MenuItem>
        <MenuItem onClick={handleSelectNone}>
          <StyledCheckbox checked={selectedCategories.length === 0} />
          <ListItemText primaryTypographyProps={{
            sx: {
              fontSize: "0.875rem",
              lineHeight: 1,
              color: "#757575",
              whiteSpace: "normal",
              wordBreak: "break-word",
            },
          }} primary="Select None" />
        </MenuItem>
        <MenuItem divider />
        {categories.map((category) => (
          <MenuItem
            key={category}
            value={category}
            onClick={(e) => handleCategoryClick(category, e)}
          >
            <StyledCheckbox checked={selectedCategories.includes(category)} />
            <ListItemText primaryTypographyProps={{
              sx: {
                fontSize: "0.875rem",
                lineHeight: 1,
                color: "#757575",
                whiteSpace: "normal",
                wordBreak: "break-word",
              },
            }} primary={category} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MatchRelevanceFilter = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleScoreChange = (score) => {
    onChange(score);
    setOpen(false);
  };

  return (
    <FormControl size="small">
      <Button
        ref={anchorRef}
        onClick={() => setOpen(true)}
        startIcon={<StarIcon fontSize="small" sx={{ color: '#55828b' }} />}
        sx={{
          color: '#55828b',
          textTransform: 'none',
          borderRadius: '10px',
          fontSize: '0.8125rem',
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          }
        }}
      >
        Match Relevance
        {value > 0 && (
          <Typography
            component="span"
            sx={{
              ml: 1,
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '10px',
              padding: '2px 8px',
              fontSize: '0.55rem'
            }}
          >
            {value}+
          </Typography>
        )}
      </Button>
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={value}
        sx={{ display: 'none' }}
        MenuProps={{
          anchorEl: anchorRef.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
      >
        <MenuItem onClick={() => handleScoreChange(0)} value={0}>All Scores</MenuItem>
        {[1, 2, 3, 4, 5].map((score) => (
          <MenuItem key={score} value={score} onClick={() => handleScoreChange(score)}>
            {score}+ and above
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const StatusFilter = ({ value, onChange }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const statuses = [
    { value: 'all', label: 'All Statuses' },
    { value: 'favorite', label: 'Favorite', icon: <FavoriteIcon sx={{ color: 'error.main', fontSize: '1.1rem' }} /> },
    { value: 'maybe', label: 'Maybe', icon: <HelpOutlineIcon sx={{ color: 'warning.main', fontSize: '1.1rem' }} /> },
    { value: 'no', label: 'Not Interested', icon: <BlockIcon sx={{ color: 'text.disabled', fontSize: '1.1rem' }} /> },
  ];

  const handleStatusChange = (newStatus) => {
    onChange(newStatus);
    setOpen(false);
  };

  const currentStatus = statuses.find(s => s.value === value) || statuses[0];

  return (
    <FormControl size="small">
      <Button
        ref={anchorRef}
        onClick={() => setOpen(true)}
        startIcon={<LabelIcon sx={{ color: '#55828b', fontSize: '1.1rem' }} />}
        sx={{
          color: '#55828b',
          textTransform: 'none',
          borderRadius: '10px',
          fontSize: '0.8125rem',
          minWidth: 'auto',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)'
          }
        }}
      >
        Status
        {value !== 'all' && (
          <Typography
            component="span"
            sx={{
              ml: 1,
              backgroundColor: 'primary.main',
              color: 'white',
              borderRadius: '10px',
              padding: '2px 8px',
              fontSize: '0.55rem'
            }}
          >
            {currentStatus.label}
          </Typography>
        )}
      </Button>
      <Select
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={value}
        sx={{ display: 'none' }}
        MenuProps={{
          anchorEl: anchorRef.current,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          }
        }}
      >
        {statuses.map((status) => (
          <MenuItem
            key={status.value}
            value={status.value}
            onClick={() => handleStatusChange(status.value)}
            sx={{ py: 1 }}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              {status.icon}
            </ListItemIcon>
            <ListItemText
              primary={status.label}
              primaryTypographyProps={{
                sx: { fontSize: '0.875rem' }
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const CustomTableToolbar = ({
  opportunities,
  selectedCategories,
  onCategoryChange,
  selectedStatus = 'all',
  onStatusChange,
  matchRelevanceScore = 0,
  onMatchRelevanceChange
}) => {
  return (
    <Box className="flex justify-between items-center p-2">
      <Box className="flex items-center gap-2">
        <GridToolbarColumnsButton />
        <Divider orientation="vertical" sx={{ py: 1 }} />
        <CategoryFilter
          opportunities={opportunities}
          selectedCategories={selectedCategories}
          onFilterChange={onCategoryChange}
        />
        <Divider orientation="vertical" sx={{ py: 1 }} />
        <MatchRelevanceFilter
          value={matchRelevanceScore}
          onChange={onMatchRelevanceChange}
        />
        <Divider orientation="vertical" sx={{ py: 1 }} />
        <StatusFilter
          value={selectedStatus}
          onChange={onStatusChange}
        />
      </Box>
      <Box>
        <GridToolbarQuickFilter />
      </Box>
    </Box>
  );
};

export default CustomTableToolbar;