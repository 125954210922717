import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../store";

export const addFileWithDetails = createAsyncThunk(
  'projectFiles/addFileWithDetails',
  async (file, { rejectWithValue }) => {
    try {
      const [signedResponse, metadataResponse] = await Promise.all([
        api.get(`file/getSignedURL/${file.id}`),
        api.post("/file/getMetadataForFile", { fileId: file.id })
      ]);

      return {
        ...file,
        uri: signedResponse.data.url,
        metadata: metadataResponse.data,
      };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  files: [],
  currentFile: null,
  isLoading: false,
  error: null,
};

const projectFileSlice = createSlice({
  name: "projectFiles",
  initialState,
  reducers: {
    setProjectFiles: (state, action) => {
      state.files = action.payload;
    },
    removeFile: (state, action) => {
      const removedFileId = action.payload;
      const removedFileIndex = state.files.findIndex(file => file.id === removedFileId);

      // Remove the file from the files array
      state.files = state.files.filter(file => file.id !== removedFileId);

      // If the removed file was the current file, update the current file
      if (state.currentFile && state.currentFile.id === removedFileId) {
        if (state.files.length > 0) {
          // If there are remaining files, set the next file as current
          // If it was the last file, set the first file as current
          const nextIndex = removedFileIndex < state.files.length ? removedFileIndex : 0;
          state.currentFile = state.files[nextIndex];
        } else {
          // If no files remain, set currentFile to null
          state.currentFile = null;
        }
      }
    },
    setCurrentFile: (state, action) => {
      state.currentFile = action.payload;
    },
    updateFile: (state, action) => {
      const index = state.files.findIndex(file => file.id === action.payload.id);
      if (index !== -1) {
        state.files[index] = action.payload;
        // If the updated file is the current file, update currentFile as well
        if (state.currentFile && state.currentFile.id === action.payload.id) {
          state.currentFile = action.payload;
        }
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addFileWithDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFileWithDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.files.push(action.payload);
      })
      .addCase(addFileWithDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setProjectFiles,
  removeFile,
  setCurrentFile,
  updateFile,
  setLoading,
  setError,
} = projectFileSlice.actions;

export default projectFileSlice.reducer;