import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  IconButton,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import RocketLaunchRoundedIcon from '@mui/icons-material/RocketLaunchRounded';
import { api } from "../store";
import { downloadAndPrepareFiles } from "../utils/Utils";
import { selectOpportunities, updateOpportunityLocally } from "../reduxstore/opportunitiesSlice";
import { useSelector, useDispatch } from "react-redux";
import { getFileDetails } from "../utils/Utils";
import OpportunityFeedbackDialog from "../Components/rfp/OpportunityFeedbackDialog";
import StatusCell from "../Components/rfp/StatusCell";


const OpportunityDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const user = location.state?.user;
  const [isFavorited, setIsFavorite] = useState(false)
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [opportunity, setOpportunity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [projectCreationLoading, setprojectCreationLoading] = useState(false);
  const dispatch = useDispatch();
  const opportunities = useSelector(selectOpportunities);
  const [tabValue, setTabValue] = useState(0);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    fetchOpportunityDetails();
  }, [id]);

  const fetchOpportunityDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/capture/getOpportunity/${id}`);
      const opportunity = response.data.opportunity;
      setError(null);
      setOpportunity(opportunity);
      // 9828526895
      const isFavorited = opportunity.favoritedBy?.some(
        fav => fav.userId === user._id
      );
      setIsFavorite(isFavorited);
    } catch (err) {
      setError("Failed to fetch opportunity details");
      console.error("Error fetching opportunity details:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    const page = searchParams.get('page') || '0';
    const pageSize = searchParams.get('pageSize') || '10';
    navigate(`/rfx-opportunities?page=${page}&pageSize=${pageSize}`, {
      state: { lastViewedId: id }
    });
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !opportunity) {
    return (
      <Box sx={{ margin: "1.5rem 2rem" }}>
        <Alert severity={error ? "error" : "warning"}>
          {error || "Opportunity not found"}
        </Alert>
      </Box>
    );
  }

  const formattedDate = new Date(opportunity.deadline).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const handleProjectCreation = async () => {
    try {
      setprojectCreationLoading(true);
      // Prepare the files from zip
      let files;
      let downloadSuccessful = true;
      try {
        files = await downloadAndPrepareFiles(opportunity.document_link);
      } catch (error) {
        downloadSuccessful = false;
        console.error("Error downloading files:", error)
      }


      // Navigate to create project with prefilled data
      navigate('/new-rfp', {
        state: {
          prefillData: {
            projectName: opportunity.title,
            clientName: opportunity.overview.issuer,
            dueDate: opportunity.deadline,
            preparedFiles: files,
            showFileError: !downloadSuccessful,
            fileURL: opportunity.document_link
          }
        }
      });
    } catch (error) {
      console.error('Error preparing project:', error);
      // Handle error appropriately
    } finally {
      setprojectCreationLoading(false)
    }
  };

  const handleStatusChange = async (rfx_id, newStatus) => {
    try {
      // Optimistically update UI
      // update opportunity state with status
      const updatedOpportunity = {
        ...opportunity,
        status: opportunity.status
          ? opportunity.status.some(s => s.userId === user._id)
            // Update existing user's status
            ? opportunity.status.map(s =>
              s.userId === user._id
                ? {
                  userId: user._id,
                  status: newStatus,
                  updatedAt: new Date().toISOString()
                }
                : s
            )
            // Add new status for user
            : [
              ...opportunity.status,
              {
                userId: user._id,
                status: newStatus,
                updatedAt: new Date().toISOString()
              }
            ]
          // Create new status array
          : [{
            userId: user._id,
            status: newStatus,
            updatedAt: new Date().toISOString()
          }]
      };

      setOpportunity(updatedOpportunity);


      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: [
            ...(opportunities.find(o => o.rfx_id === rfx_id)?.status || []).filter(s => s.userId !== user._id),
            {
              userId: user._id,
              status: newStatus,
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));

      // Update backend
      await api.put(`/capture/opportunities/${rfx_id}`, {
        status: newStatus
      });
    } catch (err) {
      console.error("Error updating status:", err);
      // Revert on error
      dispatch(updateOpportunityLocally({
        rfx_id,
        changes: {
          status: [
            ...(opportunities.find(o => o.rfx_id === rfx_id)?.status || []).filter(s => s.userId !== user._id),
            {
              userId: user._id,
              status: '',
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));
    }
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  const AnalysisContent = () => (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="secondaryText" sx={{ fontWeight: 'bold', mb: 1 }}>
          Match Score
        </Typography>
        <Typography variant="h4" color="primary.main">
          {opportunity.scoring.score}/5
        </Typography>
      </Box>

      {opportunity.analysis_status === "partial" && (
        <Typography sx={{ mb: 3, lineHeight: 1.6, fontStyle: "italic", fontSize: "0.875rem", color: "grey" }}>
          Partial analysis available. Some RFx files could not be retrieved for this opportunity.
        </Typography>
      )}

      <Divider sx={{ my: 3 }} />

      {opportunity.analysis_status === "failed" && (
        <Typography variant="primaryText" sx={{ mb: 3, lineHeight: 1.6 }}>
          Scoring data is unavailable. We were unable to retrieve the RFx files for this opportunity.
        </Typography>
      )}

      {opportunity.scoring.scoring_reasoning && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="secondaryText" sx={{ fontWeight: "bold", color: "black", mb: 2, textTransform: 'capitalize' }}>
            Analysis
          </Typography>
          <Typography variant="primaryText" sx={{ mb: 3, lineHeight: 1.6 }}>
            {opportunity.scoring.scoring_reasoning}
          </Typography>
        </Box>
      )}

      {opportunity.scoring.pros && opportunity.scoring.pros.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="secondaryText" sx={{ fontWeight: "bold", color: "black", mb: 2, textTransform: 'capitalize' }}>
            Positives
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {opportunity.scoring.pros.map((positive, index) => (
              <Chip
                key={index}
                label={positive}
                color="success"
                variant="outlined"
                sx={{
                  mb: 1,
                  height: 'auto',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    display: 'block',
                    padding: '8px 12px',
                    lineHeight: 1.4
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      )}

      {opportunity.scoring.cons && opportunity.scoring.cons.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="secondaryText" sx={{ fontWeight: "bold", color: "black", mb: 2, textTransform: 'capitalize' }}>
            Negatives
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {opportunity.scoring.cons.map((negative, index) => (
              <Chip
                key={index}
                label={negative}
                color="error"
                variant="outlined"
                sx={{
                  mb: 1,
                  height: 'auto',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    display: 'block',
                    padding: '8px 12px',
                    lineHeight: 1.4
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      )}

      {opportunity.scoring.red_flags && opportunity.scoring.red_flags.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Typography variant="secondaryText" sx={{ fontWeight: "bold", color: "black", mb: 2, textTransform: 'capitalize' }}>
            Red Flags
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            {opportunity.scoring.red_flags.map((flag, index) => (
              <Chip
                key={index}
                label={flag}
                color="error"
                variant="filled"
                sx={{
                  mb: 1,
                  height: 'auto',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    display: 'block',
                    padding: '8px 12px',
                    lineHeight: 1.4
                  }
                }}
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );

  const AntTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    fontWeight: "500",
    width: "8rem",
    minHeight: "3rem",
  }));

  const FilesContent = () => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(true);
    const documentLink = opportunity.document_link

    useEffect(() => {
      const fetchFileDetails = async () => {
        try {
          const allFiles = [];

          const fileDetails = await getFileDetails(documentLink);
          allFiles.push(...fileDetails.map(file => ({
            ...file,
            downloadUrl: documentLink
          })));

          setFiles(allFiles);
        } catch (error) {
          console.error('Error fetching file details:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchFileDetails();
    }, [documentLink]);

    const handleDownload = () => {
      window.open(opportunity.document_link, '_blank');
    };

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
          <CircularProgress />
        </Box>
      );
    }

    if (files.length === 0) {
      return (
        <Typography variant="body1" color="text.secondary" textAlign="center">
          No files available
        </Typography>
      );
    }

    return (
      <Box>
        <Alert severity="warning" sx={{ mb: 2 }}>
          The files shown here may not be up to date. Always check the original issuer portal for additional files and addendums.
        </Alert>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', p: 2 }}>
          <Typography variant="primaryText" sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            Click
            <Typography
              component="span"
              variant="primaryText"
              onClick={handleDownload}
              sx={{
                cursor: 'pointer',
                color: 'primary.main',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              here
            </Typography>
            to download files
          </Typography>
        </Box>

        <Table stickyHeader sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell width="65%" sx={{ fontWeight: '450', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>File Name</TableCell>
              <TableCell width="35%" sx={{ fontWeight: '450', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => (
              <TableRow
                key={`${file.fileName}-${index}`}
                hover
                sx={{
                  '& td': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)'
                  }
                }}
              >
                <TableCell width="65%">
                  <Typography
                    variant="primaryText"
                    sx={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word'
                    }}
                  >
                    {file.fileName}
                  </Typography>
                </TableCell>
                <TableCell width="35%">
                  <Typography
                    variant="secondaryText"
                    color="text.secondary"
                    sx={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word'
                    }}
                  >
                    {file.fileType}
                  </Typography>
                </TableCell>
                <TableCell>
                  {file.tag && (
                    <Chip
                      label={file.tag}
                      size="small"
                      color="primary"
                      variant="outlined"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </Box>
    );
  };

  const handleFeedbackSubmit = async ({ score, reasoning }) => {
    try {
      await api.put(`/capture/opportunities/${opportunity.rfx_id}`, {
        score,
        scoring_reasoning: reasoning,
      });
      dispatch(updateOpportunityLocally({
        rfx_id: opportunity.rfx_id,
        changes: {
          feedback: [
            ...(opportunities.find(o => o.rfx_id === opportunity.rfx_id)?.feedback || []),
            {
              score,
              scoring_reasoning: reasoning,
              userId: user._id,
              updatedAt: new Date().toISOString()
            }
          ]
        }
      }));
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };


  return (
    <Box sx={{ margin: "1.5rem 2rem" }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBack}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="pageHeading" component="span">
            {opportunity.title}
          </Typography>
          <StatusCell
            params={{ row: opportunity }}
            onStatusChange={handleStatusChange}
            variant="detail"
            userId={user._id}
          />
        </Box>
        <Box sx={{ gap: "0.8rem", display: "flex" }}>
          {/* <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            sx={{ ml: 2 }}
          >
            Download RFX Files
          </Button> */}
          <Button
            variant="outlined"
            startIcon={<FeedbackRoundedIcon />}
            onClick={() => setFeedbackDialogOpen(true)}
            sx={{ ml: 2 }}
          >
            Feedback
          </Button>
          <Button
            onClick={handleProjectCreation}
            startIcon={projectCreationLoading ? <CircularProgress size="17px" color="inherit" /> : <RocketLaunchRoundedIcon fontSize="small" sx={{ transform: 'rotate(-5deg)', marginBottom: "0.3rem" }} />}
            loading={projectCreationLoading}
            loadingPosition="start"
            variant="contained"
          >
            Start Project
          </Button>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={3} >
        {/* Left Column - Overview */}
        <Grid item xs={12} md={7} sx={{ mb: 2 }}>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              borderRadius: 2,
              height: '100%'
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                Due Date: {formattedDate}
              </Typography>
              {opportunity.question_answer_date && opportunity.question_answer_date != "0000-00-00" && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Question Answer Date: {new Date(opportunity.question_answer_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
              )}
              {opportunity.preproposal_date && opportunity.preproposal_date != "0000-00-00" && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Preproposal Date: {new Date(opportunity.preproposal_date).toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}
                </Typography>
              )}
              {opportunity.eligibility && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Eligibility: {opportunity.eligibility}
                </Typography>
              )}
              {opportunity.country && opportunity.state && (
                <Typography variant="secondaryText" color="text.secondary" gutterBottom>
                  Location: {opportunity.state}, {opportunity.country}
                </Typography>
              )}
            </Box>

            <Divider sx={{ my: 3 }} />

            {opportunity.overview ? Object.entries(opportunity.overview).map(([key, value]) => (
              <Box
                key={key}
                sx={{
                  mb: 2  // Add margin bottom for spacing between sections
                }}
              >
                <Typography
                  variant="secondaryText"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    mb: 1,  // Reduced margin bottom
                    textTransform: 'capitalize'
                  }}
                >
                  {key.split('_').join(' ')}
                </Typography>
                {Array.isArray(value) ? (
                  <List dense sx={{ pl: 2 }}>  {/* Added left padding for better hierarchy */}
                    {value.map((item, index) => (
                      <ListItem key={index} sx={{ px: 0, mt: 0 }}>  {/* Removed default padding */}
                        <ListItemText
                          primary={`${index + 1}. ${item}`}
                          sx={{
                            '& .MuiListItemText-primary': {
                              fontSize: '0.875rem',
                              lineHeight: 1.5,
                              color: 'text.primary'  // Ensure consistent text color
                            }
                          }}
                        />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography
                    variant="primaryText"
                    sx={{
                      lineHeight: 1.6,
                      pl: 2  // Added left padding to align with lists
                    }}
                  >
                    {value}
                  </Typography>
                )}
              </Box>
            )) : opportunity.scope && <Typography
              variant="primaryText"
              sx={{
                lineHeight: 1.6,
                pl: 2  // Added left padding to align with lists
              }}
            >
              {opportunity.scope}
            </Typography>}
          </Paper>
        </Grid>

        {/* Right Column - Scoring */}
        <Grid item xs={12} md={5} sx={{ mb: 2 }}>
          <Paper elevation={1}
            sx={{
              p: 2,
              borderRadius: 2,
              height: '100%'
            }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabChange} aria-label="opportunity tabs">
                <AntTab label="Analysis" />
                <AntTab label="Files" />
              </Tabs>
            </Box>

            <TabPanel value={tabValue} index={0}>
              <AnalysisContent />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FilesContent />
            </TabPanel>
          </Paper>

        </Grid>
      </Grid>
      <OpportunityFeedbackDialog
        open={feedbackDialogOpen}
        onClose={() => setFeedbackDialogOpen(false)}
        opportunity={opportunity}
        onSubmit={handleFeedbackSubmit}
        user={user}
      />
    </Box>
  );
};

export default OpportunityDetail;