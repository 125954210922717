import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Alert
} from "@mui/material";
import { api } from "../store";
import FileUploader from "./FileUploader";
import { allowedContentTypesForTranscription } from "../util";

const FileUploaderModal = ({
  isOpen,
  onClose,
  modalHeading,
  refreshFiles,
  projectId,
  allowedFileTypes,
  sendNotification,
  allowAudio,
  displayTags,
  availableTagOptions,
  extractFileData,
  processFile
}) => {
  const [uploadErrorMessages, setUploadErrorMessages] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const [filesAndStatus, setFilesAndStatus] = useState([]);
  const [showTagErrors, setShowTagErrors] = useState(false);

  function updateFileUploaderStatus(updatedFilesAndStatus) {
    setUploadErrorMessages([]);
    setFilesAndStatus(updatedFilesAndStatus);
    setUploadError(false);
  }

  async function transcribeAudioFile(fileName, audioFile, contentType) {
    try {
      await api
        .post(
          "ai/transcribe",
          {
            projectId: projectId,
            fileName,
            filePath: audioFile,
            contentType,
          },
          {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
          }
        )
        .then(() => {
          sendNotification(fileName);
          refreshFiles();
          setTimeout(() => {
            sendNotification("");
          }, 8000);
        });
    } catch (error) {
      throw error;
    }
  }

  async function deleteFile(fileId) {
    try {
      // also delete the file vectors if they exist
      await api.delete(
        `archives/deleteProjectFileVectors/?file_id=${fileId}&namespace=projects`
      );
      const response = await api.delete("/file/deleteFile", {
        params: {
          fileId: fileId,
          ownerId: projectId.toString(),
        },
      });

      return response.data.message;
    } catch (err) {
      throw err;
    }
  }

  async function uploadFiles(files) {
    const formData = new FormData();
    files.forEach(({ file, tag }, index) => {
      formData.append(`files`, file);
      formData.append(`fileTags[${index}]`, tag);
    });
    formData.append("ownerId", projectId);
    try {
      const response = await api.post("file/uploadFiles", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }

  const submitUploadFiles = async () => {
    if (displayTags && availableTagOptions && availableTagOptions.length > 0) {
      const allFilesHaveTags = filesAndStatus.every(file => file.tag);
      if (!allFilesHaveTags) {
        setShowTagErrors(true);
        return; // Stop the upload process if not all files have tags
      }
    }
    let updatedFilesAndStatus = filesAndStatus.map((fs) =>
      fs.status === "edit" ? { ...fs, status: "uploading" } : fs
    );

    try {
      setFilesAndStatus(updatedFilesAndStatus);
      setUploadErrorMessages([]);

      const filesToUpload = updatedFilesAndStatus.map((fs) => ({
        file: fs.file,
        tag: fs.tag,
      }));

      const uploadResponses =
        filesToUpload.length > 0 ? (await uploadFiles(filesToUpload)).data : [];

      for (let [index, response] of uploadResponses.entries()) {
        if (response.status === 200) {
          if (extractFileData) {
            try {
              await processFile(response.filePath);
              updatedFilesAndStatus[index].status = "complete";
            } catch (error) {
              try {
                await deleteFile(response.filePath);
              } catch (deleteError) {
                console.log(deleteError);
              }
              console.error("Error during file processing", error);
              updatedFilesAndStatus[index].status = "edit";
              setUploadErrorMessages((prevErrorMessages) => [
                ...prevErrorMessages,
                `Error processing file: ${response.fileName}`,
              ]);
            }
          }

          if (
            allowedContentTypesForTranscription.includes(
              response.fileContentType
            )
          ) {
            try {
              transcribeAudioFile(
                response.fileName,
                response.filePath,
                response.fileContentType
              );
            } catch (transcriptionError) {
              console.error(
                "Transcription failed for file",
                response.fileName,
                transcriptionError
              );
              try {
                await deleteFile(response.filePath);
              } catch (deleteError) {
                console.log(deleteError);
              }
              updatedFilesAndStatus[index].status = "edit";
              setUploadErrorMessages((prevErrorMessages) => [
                ...prevErrorMessages,
                `Could not upload file: ${response.fileName}`,
              ]);
            }
          }
        } else {
          console.log("File upload failed for ", response.fileName);
          updatedFilesAndStatus[index].status = "edit";
          setUploadErrorMessages((prevErrorMessages) => [
            ...prevErrorMessages,
            response.message,
          ]);
        }
      }
    } catch (error) {
      updatedFilesAndStatus = filesAndStatus.map((fs) => ({
        ...fs,
        status: "edit"
      }));
      console.error("Error during file upload process", error);
      setUploadError(true);
    } finally {
      setFilesAndStatus(updatedFilesAndStatus);
      const completedFilesAndStatus = updatedFilesAndStatus.filter(
        (fs) => fs.status === "complete"
      );
      if (completedFilesAndStatus.length === filesAndStatus.length) {
        refreshFiles();
        onClose();
        setFilesAndStatus([]);
        setUploadErrorMessages([]);
      }
    }
  };

  const closeModal = () => {
    refreshFiles();
    onClose();
    setFilesAndStatus([]);
    setUploadErrorMessages([]);
  };

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      aria-labelledby="file-uploader-modal-title"
    >
      <Box sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: {
          xs: "80%",
          sm: "80%",
          md: "70%",
          lg: "60%",
          xl: "50%",
        },
        maxWidth: 600,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: { xs: 2, sm: 3, md: 4 },
        maxHeight: "90vh",
        overflowY: "auto",
        borderRadius: 2,
      }}>
        <Typography id="file-uploader-modal-title" variant="h6" component="h2">
          {modalHeading}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          Accepted formats: {allowedFileTypes}
        </Typography>
        <Typography variant="body2">
          Maximum file size is 2GB.
        </Typography>
        <Box sx={{ mt: 2, position: "relative" }}>
          <FileUploader
            uploadErrorMessages={uploadErrorMessages}
            filesAndStatus={filesAndStatus}
            updateFileUploaderStatus={updateFileUploaderStatus}
            maxFiles={100}
            uploadError={uploadError}
            allowAudioFiles={allowAudio}
            acceptTypes={allowedFileTypes}
            displayTags={displayTags}
            tagOptions={availableTagOptions}
            showTagErrors={showTagErrors}
          />
        </Box>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={closeModal} sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitUploadFiles}>
            Upload
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FileUploaderModal;