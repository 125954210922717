import React, { useEffect, useRef, useState } from 'react';
import {
  TextField, Grid, Button, Paper, Typography, Box,
  Select, MenuItem, Chip, FormControl, InputLabel, OutlinedInput, Slider,
  IconButton, List, ListItem, ListItemText, Switch, FormControlLabel, Divider, TextareaAutosize, FormHelperText,
  Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { api } from "../../store";

const MenuProps = {
  PaperProps: {
    style: { maxHeight: 48 * 4.5 + 8 },
  },
};

const ContractTypes = ['Local', 'State', 'Federal', 'Corporate'];
const USStates = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'Florida',
  'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
  'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire',
  'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
  'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virginia', 'Washington',
  'West Virginia', 'Wisconsin', 'Wyoming'];
const naicsCodes = require('./NAICS_Codes.json');
const rfpmartCategories = require('./RFPMart_Categories.json');

const SectionTitle = ({ title }) => (
  <Grid item xs={12}>
    <Divider sx={{ my: 3 }} />
    <Typography variant="pageHeading" gutterBottom color="primary">
      {title}
    </Typography>
  </Grid>
);

const ItemList = ({ field, label, items, newItemValue, onNewItemChange, onAddItem, onDeleteItem, isTextArea = false }) => {
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && newItemValue.trim()) {
      e.preventDefault();
      onAddItem(newItemValue.trim());
    }
  };

  const handleBlur = () => {
    if (newItemValue.trim()) {
      onAddItem(newItemValue.trim());
    }
  };

  return (
    <Box>
      <Typography variant="sectionHeading" sx={{ color: 'black' }} gutterBottom>{label}s</Typography>
      <Box sx={{ display: 'flex', gap: 0.5, mb: 2, mt: 2, alignItems: 'center' }}>

        {isTextArea ? (
          <TextareaAutosize
            minRows={3}
            placeholder={`Add ${label}`}
            value={newItemValue}
            onChange={(e) => onNewItemChange(e.target.value)}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              borderColor: '#ccc',
              fontFamily: 'inherit',
              fontSize: '0.875rem',
              border: '1px solid #e0e0e0'
            }}
          />
        ) : (
          <TextField
            fullWidth
            label={`Add ${label}`}
            value={newItemValue}
            onChange={(e) => onNewItemChange(e.target.value)}
            onKeyPress={handleKeyPress}
            onBlur={handleBlur}
            helperText={`Add your ${label.toLowerCase()} and press Enter or click +`}
            style={{ fontSize: '0.875rem' }}
          />
        )}
        <IconButton
          onClick={() => {
            if (newItemValue.trim()) {
              onAddItem(newItemValue.trim());
            }
          }}
          color="primary"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </Box>
      <List sx={{
        // backgroundColor: '#f5f5f5',
        // border: '1px solid #e0e0e0',
        // borderRadius: '8px',
        // padding: '8px',
        marginRight: 5,
        '& .MuiListItem-root': {
          backgroundColor: '#ffffff',
          borderRadius: '4px',
          marginBottom: '1rem',
          border: '1px solid #e0e0e0',
          '&:last-child': {
            marginBottom: 0
          }
        }
      }}>
        {items.map((item, index) => (
          <ListItem
            key={index}
            secondaryAction={
              <IconButton edge="end" onClick={() => onDeleteItem(index)}>
                <DeleteIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            }
          >
            <ListItemText primary={item} primaryTypographyProps={{ variant: 'primaryText' }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const CaptureSettings = ({ orgId, businessProfile, onUpdateSettings }) => {
  const [formData, setFormData] = useState({
    businessUEICode: '',
    certifications: [],
    naicsCodes: [],
    preferredCountry: 'USA',
    preferredStates: [],
    preferredContractType: [],
    preferredContractMinValue: 50000,
    preferredContractMaxValue: 150000,
    dealBreakers: [],
    dealMakers: [],
    industries: [],
    subcontractorTasks: [],
    preferHiringSubcontractors: false,
    capabilityStatementFileId: '',
    rfpMartCategories: []
  });
  const [capabilityStatementFileInfo, setCapabilityStatementFileInfo] = useState({});
  const [capabilityStatementSignedUrl, setCapabilityStatementSignedUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      var formData = new FormData();
      formData.append("files", file);
      formData.append("ownerId", orgId);
      formData.append("replace", "true");
      const response = await api
        .post("file/uploadFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      if (response.data[0].status === 200) {
        onUpdateSettings(
          { capabilityStatementFileId: response.data[0].filePath }
        );
      }
    }
  };

  const getFileInfo = async (fileId) => {
    const response = await api.get(`file/getFileInfo/${fileId}`);
    setCapabilityStatementFileInfo(response.data.fileInfo);
    setCapabilityStatementSignedUrl(response.data.signedUrl);
  }

  const [newItem, setNewItem] = React.useState({
    dealBreaker: '',
    dealMaker: '',
    industry: '',
    subcontractor: ''
  });

  useEffect(() => {
    if (businessProfile) {
      console.log(businessProfile);
      setFormData({
        businessUEICode: businessProfile?.businessUEICode || '',
        certifications: businessProfile?.certifications || [],
        naicsCodes: businessProfile?.naicsCode || [],
        preferredCountry: 'USA',
        preferredStates: businessProfile?.preferredStates || [],
        preferredContractType: businessProfile?.preferredContractType || [],
        preferredContractMinValue: businessProfile?.preferredContractMinValue || 50000,
        preferredContractMaxValue: businessProfile?.preferredContractMaxValue || 150000,
        dealBreakers: businessProfile?.dealBreakers || [],
        dealMakers: businessProfile?.dealMakers || [],
        industries: businessProfile?.preferredIndustries || [],
        subcontractorTasks: businessProfile?.subcontractorTasks || [],
        preferHiringSubcontractors: businessProfile?.preferHiringSubcontractors || false,
        capabilityStatementFileId: businessProfile?.capabilityStatementFileId || '',
        rfpMartCategories: businessProfile?.rfpMartCategories || []
      });
    }
  }, [businessProfile]);

  useEffect(() => {
    if (formData.capabilityStatementFileId) {
      getFileInfo(formData.capabilityStatementFileId);
    }
  }, [formData.capabilityStatementFileId]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFormData(prev => ({ ...prev, [id]: value }));
  };

  const handleSwitchChange = (event) => {
    setFormData(prev => ({
      ...prev,
      preferHiringSubcontractors: event.target.checked
    }));
  };

  const handleMultiSelectChange = (field) => (event) => {
    setFormData(prev => ({
      ...prev,
      [field]: event.target.value
    }));
  };

  const handleSliderChange = (event, newValue) => {
    setFormData(prev => ({
      ...prev,
      preferredContractMinValue: newValue[0],
      preferredContractMaxValue: newValue[1]
    }));
  };

  const handleNewItemChange = (field) => (value) => {
    setNewItem(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddItem = (field) => (value) => {
    setFormData(prev => ({
      ...prev,
      [field]: [...prev[field], value]
    }));
    setNewItem(prev => ({
      ...prev,
      [field === 'dealBreakers' ? 'dealBreaker' :
        field === 'dealMakers' ? 'dealMaker' :
          field === 'industries' ? 'industry' : 'subcontractor']: ''
    }));
  };

  const handleDeleteItem = (field) => (index) => {
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index)
    }));
  };

  const handleUpdateSettings = () => {
    setOpenDialog(true);
  };

  const handleConfirmUpdate = () => {
    onUpdateSettings(formData);
    setOpenDialog(false);
  };

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Business Identification Section */}
        <SectionTitle title="Business Identification" />
        <Grid container spacing={3} xs={12} md={8} direction="row" p={4}>
          <Grid item xs={12}>
            <TextField
              id="businessUEICode"
              label="Business UEI Code"
              fullWidth
              value={formData.businessUEICode}
              onChange={handleInputChange}
              helperText="Unique Entity Identifier for your business"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="naics-code-label">NAICS Code</InputLabel>
              <Select
                labelId="naics-code-label"
                multiple
                id="naicsCode"
                value={formData.naicsCodes || ''}
                helperText="Select NAICS codes relevant to your business"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const codeOnly = typeof selectedValue === 'string' ? selectedValue.split(':')[0].trim() : selectedValue;
                  handleMultiSelectChange('naicsCode')({ target: { value: codeOnly } });
                }}
                input={<OutlinedInput label="NAICS Code" />}
                MenuProps={MenuProps}
                name="naicsCode"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((code) => {
                      const naicsItem = naicsCodes.find(n => n.code === code);
                      return (
                        <Chip
                          key={code}
                          label={`${code}: ${naicsItem?.description}`}
                          onDelete={() => handleMultiSelectChange('naicsCode')({
                            target: {
                              value: formData.naicsCodes.filter(c => c !== code)
                            }
                          })}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {naicsCodes.map((naics) => (
                  <MenuItem key={naics.code} value={naics.code}>
                    <Typography variant="primaryText" noWrap>
                      {naics.code}: {naics.description}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select NAICS codes relevant to your business</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="rfpmart-category-label">Category</InputLabel>
              <Select
                labelId="rfpmart-category-label"
                multiple
                id="rfpMartCategories"
                value={formData.rfpMartCategories || ''}
                helperText="Select RFPMart categories relevant to your business"
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  const codeOnly = typeof selectedValue === 'string' ? selectedValue.split(':')[0].trim() : selectedValue;
                  handleMultiSelectChange('rfpMartCategories')({ target: { value: codeOnly } });
                }}
                input={<OutlinedInput label="RFPMart Category" />}
                MenuProps={MenuProps}
                name="rfpMartCategories"
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((code) => {
                      const rfpMartItem = rfpmartCategories.find(n => n.code === code);
                      return (
                        <Chip
                          key={code}
                          label={`${code}: ${rfpMartItem?.description}`}
                          onDelete={() => handleMultiSelectChange('rfpMartCategories')({
                            target: {
                              value: formData.rfpMartCategories.filter(c => c !== code)
                            }
                          })}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {rfpmartCategories.map((rfpMart) => (
                  <MenuItem key={rfpMart.code} value={rfpMart.code}>
                    <Typography variant="primaryText" noWrap>
                      {rfpMart.code}: {rfpMart.description}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>Select categories relevant to your business</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="sectionHeading" sx={{ color: 'black' }} gutterBottom>
              Capability Statement
            </Typography>
            {formData.capabilityStatementFileId ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                <Paper elevation={2} sx={{ border: '1px solid #e0e0e0', borderRadius: '4px', padding: '0.5rem', fontSize: '0.875rem', p: 1, flex: 1 }} onClick={() => window.open(capabilityStatementSignedUrl, '_blank')}>
                  {capabilityStatementFileInfo?.name}
                </Paper>

                <Button
                  variant="outlined"
                  startIcon={<UploadFileIcon />}
                  onClick={() => fileInputRef.current?.click()}
                  size="small"
                  sx={{ minWidth: 'fit-content' }}
                >
                  Replace File
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', mt: 2 }}>
                <Button
                  variant="outlined"
                  startIcon={<FileUploadIcon />}
                  onClick={() => fileInputRef.current?.click()}
                >
                  Upload Capability Statement
                </Button>
              </Box>
            )}
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              accept=".pdf,.doc,.docx"
              style={{ display: 'none' }}
            />
          </Grid>
        </Grid>


        {/* Location & Contract Preferences Section */}
        <SectionTitle title="Location & Contract Preferences" />
        <Grid container spacing={3} xs={12} md={8} direction="row" p={4}>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select value="USA" disabled label="Country">
                <MenuItem value="USA">USA</MenuItem>
              </Select>
              <FormHelperText>Currently only USA is supported</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel>States</InputLabel>
              <Select
                multiple
                value={formData.preferredStates}
                onChange={handleMultiSelectChange('preferredStates')}
                input={<OutlinedInput label="States" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={(e) => {
                          console.log("here")
                          e.stopPropagation(); // Prevent event bubbling to parent Select
                          const newStates = formData.preferredStates.filter(state => state !== value);
                          handleMultiSelectChange('preferredStates')({ target: { value: newStates } });
                        }}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {USStates.map((state) => (
                  <MenuItem key={state} value={state}>{state}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Select states where you are active. Leave empty to be active in all states.</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <FormControl fullWidth>
              <InputLabel>Contract Types</InputLabel>
              <Select
                multiple
                value={formData.preferredContractType}
                onChange={handleMultiSelectChange('preferredContractType')}
                input={<OutlinedInput label="Contract Types" />}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        onDelete={() => handleMultiSelectChange('preferredContractType')([
                          ...formData.preferredContractType.filter(type => type !== value)
                        ])}
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {ContractTypes.map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                ))}
              </Select>
              <FormHelperText>Select contract types you prefer</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography gutterBottom variant="primaryText">Contract Value Range ($)</Typography>
            <Slider
              value={[formData.preferredContractMinValue, formData.preferredContractMaxValue]}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={0}
              max={500000}
              step={10000}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <TextField
                label="Minimum Value"
                value={formData.preferredContractMinValue}
                type="number"
                size="small"
                helperText="Minimum contract value"
              />
              <TextField
                label="Maximum Value"
                value={formData.preferredContractMaxValue}
                type="number"
                size="small"
                helperText="Maximum contract value"
              />
            </Box>
          </Grid>
        </Grid>


        {/* Contract Terms Section */}
        <SectionTitle title="Contract Terms" />
        <Grid item xs={12} md={6}>
          <ItemList
            field="dealBreakers"
            label="Deal Breaker"
            items={formData.dealBreakers}
            newItemValue={newItem.dealBreaker}
            onNewItemChange={handleNewItemChange('dealBreaker')}
            onAddItem={handleAddItem('dealBreakers')}
            onDeleteItem={handleDeleteItem('dealBreakers')}
            isTextArea={true}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ItemList
            field="dealMakers"
            label="Deal Maker"
            items={formData.dealMakers}
            newItemValue={newItem.dealMaker}
            onNewItemChange={handleNewItemChange('dealMaker')}
            onAddItem={handleAddItem('dealMakers')}
            onDeleteItem={handleDeleteItem('dealMakers')}
            isTextArea={true}
          />
        </Grid>

        {/* Industry & Subcontracting Section */}
        <SectionTitle title="Industry & Subcontracting" />
        <Grid container spacing={3} xs={12} md={8} direction="row" p={4}>
          <Grid item xs={12}>
            <ItemList
              field="industries"
              label="Target Industry"
              items={formData.industries}
              newItemValue={newItem.industry}
              onNewItemChange={handleNewItemChange('industry')}
              onAddItem={handleAddItem('industries')}
              onDeleteItem={handleDeleteItem('industries')}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              sx={{ fontSize: '0.875rem' }}
              control={
                <Switch
                  checked={formData.preferHiringSubcontractors}
                  onChange={handleSwitchChange}
                />
              }
              label="We hire subcontractors"
            />
          </Grid>

          {formData.preferHiringSubcontractors && (
            <Grid item xs={12}>
              <ItemList
                field="subcontractorTasks"
                label="Subcontractor Task"
                items={formData.subcontractorTasks}
                newItemValue={newItem.subcontractor}
                onNewItemChange={handleNewItemChange('subcontractor')}
                onAddItem={handleAddItem('subcontractorTasks')}
                onDeleteItem={handleDeleteItem('subcontractorTasks')}
              />
            </Grid>
          )}
        </Grid>


        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            onClick={handleUpdateSettings}
          >
            Update Settings
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Update Settings</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please note that these new settings will only affect future opportunities. Existing opportunities will maintain their current scoring.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmUpdate} variant="contained">
            Confirm Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CaptureSettings;