// src/store/itemsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../store";
import { setSelectedItem } from "./selectedItemSlice";

export const reorderItems = createAsyncThunk(
  "items/reorderItems",
  async (params, { getState, dispatch }) => {
    const { newOrder, projectId } = params;
    await api.put("/project/updateDraft", {
      projectId,
      sections: newOrder,
    });
    return newOrder;
  }
);

export const deleteItemAndUpdate = createAsyncThunk(
  "items/deleteItemAndUpdate",
  async (params, { getState, dispatch }) => {
    const { itemToDelete, projectId } = params;
    const state = getState();
    const currentItems = state.items;
    const selectedItem = state.selectedItem;

    const updatedItems = currentItems.filter(
      (item) => item.uuid !== itemToDelete.uuid
    );

    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    // If the deleted item was selected, select the next item (or the last item if it was the last one)
    if (selectedItem.uuid === itemToDelete.uuid) {
      const deletedIndex = currentItems.findIndex(
        (item) => item.uuid === itemToDelete.uuid
      );
      const newSelectedItem =
        updatedItems[deletedIndex] ||
        updatedItems[updatedItems.length - 1] ||
        null;
      if (newSelectedItem) {
        dispatch(setSelectedItem(newSelectedItem));
      }
    }

    return updatedItems;
  }
);

export const updateItem = createAsyncThunk(
  "items/updateItem",
  async ({ uuid, key, value, projectId, version }, { getState, dispatch }) => {
    const state = getState();
    const currentItems = state.items;

    // Find the item to update
    let itemToUpdate = currentItems.find((item) => item.uuid === uuid);
    if (!itemToUpdate) {
      // look for the item in sections
      for (let i = 0; i < currentItems.length; i++) {
        const section = currentItems[i].sections.find((item) => item.uuid === uuid);
        if (section) {
          itemToUpdate = section;
          break;
        }
      }
      if (!itemToUpdate) {
        throw new Error("Item not found");
      }
    }

    // Create the updated item
    let updatedValue;
    if (version !== undefined && Array.isArray(itemToUpdate[key])) {
      // If version is defined and the field is an array, update the specific index
      updatedValue = [...itemToUpdate[key]];
      updatedValue[version] = value;
    } else {
      // If version is undefined or the field is not an array, update directly
      updatedValue = value;
    }

    const updatedItem = { ...itemToUpdate, [key]: updatedValue };

    // Create a new array with the updated item, considering both documents and sections
    const updatedItems = currentItems.map((item) => {
      if (item.uuid === uuid) {
        // If the item is a top-level document
        return updatedItem;
      } else if (item.sections) {
        // If the item is a document with sections
        const updatedSections = item.sections.map((section) =>
          section.uuid === uuid ? updatedItem : section
        );
        return { ...item, sections: updatedSections };
      }
      return item;
    });

    console.log("updatedItems", updatedItems);
    // Update the draft in the backend
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    // Update the selectedItem if it's the item being updated
    const selectedItem = state.selectedItem;
    if (selectedItem.uuid === uuid) {
      dispatch(setSelectedItem(updatedItem));
    }

    return updatedItems;
  }
);

export const addItemAndUpdate = createAsyncThunk(
  "items/addItemAndUpdate",
  async ({ item, parentUuid, projectId }, { getState, dispatch }) => {
    const state = getState();
    const currentItems = state.items;

    // Add the item to the state
    dispatch(addItem({ item, parentUuid }));

    // Get the updated items after adding the new item
    const updatedItems = getState().items;

    // Update the draft in the backend
    await api.put("/project/updateDraft", {
      projectId,
      sections: updatedItems,
    });

    return updatedItems;
  }
);

const itemsSlice = createSlice({
  name: "items",
  initialState: [],
  reducers: {
    setItems: (state, action) => {
      return action.payload;
    },
    addItem: (state, action) => {
      const { item, parentUuid } = action.payload;
      if (!parentUuid) {
        // If no parentUuid is provided, add the item to the top level
        state.push(item);
      } else {
        // If parentUuid is provided, find the parent item and add to its sections
        const parentItem = state.find(i => i.uuid === parentUuid);
        if (parentItem) {
          if (!parentItem.sections) {
            parentItem.sections = [];
          }
          parentItem.sections.push(item);
        } else {
          console.error(`Parent item with uuid ${parentUuid} not found`);
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(reorderItems.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(reorderItems.rejected, (state, action) => {
        console.error("Failed to reorder items:", action.error);
      })
      .addCase(deleteItemAndUpdate.fulfilled, (state, action) => {
        console.log(action);
        return action.payload;
      })
      .addCase(deleteItemAndUpdate.rejected, (state, action) => {
        console.error("Failed to delete item and update:", action.error);
      })
      .addCase(updateItem.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(updateItem.rejected, (state, action) => {
        console.error("Failed to update item:", action.error);
      });
  },
});

export const { addItem, setItems } = itemsSlice.actions;
export default itemsSlice.reducer;
