import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LeftPanel from "./Components/LeftPanel";

const theme = createTheme({
  palette: {
    primary: {
      main: "#55828b",
      dark: "#44686f",
    },
    secondary: {
      main: "#5a0b4d",
      highlight: "#ee4266",
    },
    tertiary: {
      main: "#393939",
    },
  },
  typography: {
    fontFamily: "'Montserrat', 'sans-serif'",
    pageHeading: {
      fontSize: "1rem",
      fontWeight: 600,
      lineHeight: 1.2,
      color: "#393939",
    },
    sectionHeading: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.3,
      color: "#5a0b4d",
    },
    primaryText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#170313",
      display: 'block'
    },
    secondaryText: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#170313",
      display: 'block'
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

const Layout = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const closedNavWidth = 80; // Width in pixels when nav is closed
  const openNavWidth = 170; // Width in pixels when nav is open

  const handleMouseEnter = () => {
    setIsNavOpen(true);
  };

  const handleMouseLeave = () => {
    setIsNavOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <Box
          sx={{
            width: isNavOpen ? openNavWidth : closedNavWidth,
            flexShrink: 0,
            transition: 'width 0.35s ease',
          }}
        >
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: isNavOpen ? openNavWidth : closedNavWidth,
              height: '100vh',
              transition: 'width 0.35s ease',
            }}
          >
            {/* Left Panel Component */}
            <Box
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{ height: '100%' }}
            >
              <LeftPanel isNavOpen={isNavOpen} />
            </Box>
          </Box>
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: `calc(100% - ${isNavOpen ? openNavWidth : closedNavWidth}px)`,
            transition: 'width 0.35s ease',
            overflow: 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Layout;